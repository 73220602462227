import { useEffect, useState } from 'react';

import config from './../config';

const usePromptNetwork = (chainId: number) => {
  const [networkPrompt, setNetworkPrompt] = useState(false);
  const { ethereum } = window;

  /**
   * For more read https://github.com/NoahZinsmeister/web3-react/blob/6737868693adb7e1e28ae80499e19901e9aae45a/example/hooks.ts#L33
   * And https://docs.metamask.io/guide/ethereum-provider.html
   * @param provider ethereum provider in this case is the window.ethereum available due to metamask being installed
   * @returns
   */
  const connectToNetwork = async (provider: any, chainId: number) => {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${chainId.toString(16)}`,
          chainName: config[chainId]?.networkNames[chainId],
          nativeCurrency: {
            name: config[chainId]?.networkNames[chainId],
            symbol: config[chainId]?.networkSymbols[chainId],
            decimals: config[chainId]?.networkDecimals[chainId],
          },
          rpcUrls: [config[chainId]?.networkUrls[chainId]],
          blockExplorerUrls: [config[chainId]?.scanUrls[chainId]],
        },
      ],
    });
  };
  useEffect(() => {
    if (ethereum && ethereum.networkVersion !== chainId.toString()) {
      if(config[chainId]=== undefined){
        chainId=25;
      }
      connectToNetwork(ethereum, chainId);
      setNetworkPrompt(true);
    }
  }, [networkPrompt, ethereum, chainId]);
};

export default usePromptNetwork;

import { ethers } from 'ethers';
import useTombFinance from 'hooks/useTombFinance';
import config from '../config';

import { web3ProviderFrom } from '../tomb-finance/ether-utils';

let provider: ethers.providers.Web3Provider = null;

export function getDefaultProvider(chainId?: number): ethers.providers.Web3Provider {

  if (!provider) {
    console.log("provider: ", chainId);
    provider = new ethers.providers.Web3Provider(web3ProviderFrom(config[chainId]?.networkUrls[chainId]), chainId);
  }

  return provider;
}

import bearLogo from 'assets/tokenImg/bear.png';
import darkCroLogo from 'assets/tokenImg/darkCro.png';
import darkMineLogo from 'assets/tokenImg/darkMine.png';
import darkUsdcLogo from 'assets/tokenImg/darkUsdc.png';
import lionLogo from 'assets/tokenImg/lion.png';
import mineLogo from 'assets/tokenImg/mine.png';
import mineCroLogo from 'assets/tokenImg/mineCro.png';
import mineMusdLogo from 'assets/tokenImg/mineMusd.png';
import tigerLogo from 'assets/tokenImg/tiger.png';

import { BankInfo } from './tomb-finance';
import { Configuration } from './tomb-finance/config';

const configurations: { [env: string]: Configuration } = {
  25: {
    chainId: 25,
    networkNames: {
      25: 'Cronos Mainnet Beta',
      /*137: "Polygon Mainnet",
      56: "Binance Smart Chain",*/
      2222: 'Kava',
    },
    scanUrls: {
      25: 'https://cronoscan.com',
      /*137: "https://polygonscan.com",
      56: "https://bscscan.com/",*/
      2222: 'https://explorer.kava.io',
    },
    networkSymbols: {
      25: 'CRO',
      /*137: "MATIC",
      56: "BNB",*/
      2222: 'KAVA',
    },
    networkDecimals: {
      25: 18,
      /*137: 18,
      56: 18,*/
      2222: 18,
    },
    networkUrls: {
      25: 'https://evm.cronos.org',
      /*137: "https://polygon-rpc.com",
      56: "https://bscrpc.com",*/
      2222: 'https://evm.kava.io',
    },
    deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WFTM: ['0x654bAc3eC77d6dB497892478f854cF6e8245DcA9', 18],
      USDC: ['0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 6],
      USDT: ['0x919C1c267BC06a7039e03fcc2eF738525769109c', 6],
      WBTC: ['0x062e66477faf219f25d27dced647bf57c3107d52', 8],
      WCRO: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      MSHARE: ['0xf8b9facB7B4410F5703Eb29093302f2933D6E1Aa', 18],
      MMF: ['0x97749c9b61f878a880dfe312d2594ae07aed7656', 18],
      SVN: ['0x654bac3ec77d6db497892478f854cf6e8245dca9', 18],
      TIGER: ['0xD6597AA36DD90d7fCcBd7B8A228F2d5CdC88eAd0', 18],
      BEAR: ['0xaa22aebd60c9eb653a0ae0cb8b7367087a9b5dba', 18],
      LION: ['0x49fB98F9b4a3183Cd88e7a115144fdf00fa6fB95', 18],
      BBOND: ['0x17f43ea72ddc8f369e3c838c17a7b9f3432a37d7', 18],
      DARKCRYSTL: ['0xefa1fabc2ab6219174ad1c912f56f7de53cdc1e1', 18],
      MINE: ['0x0944d9a8dea16af1170c51e8dd3921c727a06cbd', 18],
      MUSD: ['0x95aEaF383E2e86A47c11CffdE1F7944eCB2C38C2', 18],

      'LION-SVN-LP': ['0xD440433dAA33b3e3f2b5421046EAf84bEe6F40D0', 18],
      'TIGER-SVN-LP': ['0xaDeC6aaAa0765472EE9eBe524BD3454Fd733BAB9', 18],
      'USDC-SVN-LP': ['0xe7bEEC1046007BBCf7394076C654640F32456453', 18],
      'USDC-MMF-LP': ['0x722f19bd9a1e5ba97b3020c6028c279d27e4293c', 18],
      'BEAR-MSHARE-LP': ['0xcC091518302CBca55897Fc370e722b01D57D397a', 18],
      'MMF-SVN-LP': ['0xB6E1705BfAFcf1efEE83C135C0F0210653bAB8F0', 18],
      'LION-USDC-MMF-LP': ['0x32aaaaadecbb11c474ddbd4c934523bcfecea8dd', 18],
      'TIGER-USDC-MMF-LP': ['0x4f61a3104eb671bd501b78fb49ed713803609bc0', 18],
      'BEAR-WBTC-MMF-LP': ['0x8cc728a1a35665e000b303eda9c1d774445e3d12', 18],
      'LION-USDC-LP': ['0xf2059ed015ec4ecc80f902d9fdbcd2a227bfe037', 18],
      'TIGER-USDC-LP': ['0xf6464c80448d6ec4deb7e8e5ec95b8eb768fbf69', 18],
      'LION-USDT-LP': ['0xa3c6a2f6d67354f43A0b5eC9E46467822750968C', 18],
      'TIGER-LION-LP': ['0x78Ef6D3E3d0da9B2248C11BE11743B4C573ADd25', 18],
      'BEAR-WBTC-LP': ['0x3d9e539fa44b970605658e25d18f816ce78c4007', 18],
      'DARKCRYSTL-MINE (MMF) LP': ['0xbe4C7893e79028f4956307d2efD2cf8230360c70', 18],
      'DARKCRYSTL-WCRO (MMF) LP': ['0x59505978Dcdb0c820ECf6486AFEB9b2Baa58Ff49', 18],
      'MINE-WCRO (MMF) LP': ['0x1AA703ecBb80271A0d719E4405D030F0fc46c30E', 18],
      'DARKCRYSTL-USDC (MMF) LP': ['0x40EB90721114a0A10eD7732F4dCB74D9672A9FcD', 18],
      'MINE-MUSD (MMF) LP': ['0xB13ae168C893e5bD15f6caAa1D2Da11047e393cA', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 5000,
  },
  /*56: {
    chainId: 56,
    networkNames: {
      25: 'Cronos Mainnet Beta',
      137: "Polygon Mainnet",
      56: "Binance Smart Chain",
      2222: 'Kava'
    },
    scanUrls: {
      25: 'https://cronoscan.com',
      137: "https://polygonscan.com",
      56: "https://bscscan.com/",
      2222: 'https://explorer.kava.io'
    },
    networkSymbols: {
      25: 'CRO',
      137: "MATIC",
      56: "BNB",
      2222: 'KAVA'

    },
    networkDecimals: {
      25: 18,
      137: 18,
      56: 18,
      2222: 18

    },
    networkUrls: {
      25: 'https://evm.cronos.org',
      137: "https://polygon-rpc.com",
      56: "https://bscrpc.com",
      2222: 'https://evm.kava.io'

    },
    deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WFTM: ['0x654bAc3eC77d6dB497892478f854cF6e8245DcA9', 18],
      USDC: ['0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 6],
      WBTC: ['0x062e66477faf219f25d27dced647bf57c3107d52', 8],
      WCRO: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      MSHARE: ['0xf8b9facB7B4410F5703Eb29093302f2933D6E1Aa', 18],
      MMF: ['0x97749c9b61f878a880dfe312d2594ae07aed7656', 18],
      SVN: ['0x654bac3ec77d6db497892478f854cf6e8245dca9', 18],
      BEAR: ['0xaa22aebd60c9eb653a0ae0cb8b7367087a9b5dba', 18],
      LION: ['0x49fB98F9b4a3183Cd88e7a115144fdf00fa6fB95', 18],
      BBOND: ['0x17f43ea72ddc8f369e3c838c17a7b9f3432a37d7', 18],
      DARKCRYSTL: ['0xefa1fabc2ab6219174ad1c912f56f7de53cdc1e1', 18],
      MINE: ['0x0944d9a8dea16af1170c51e8dd3921c727a06cbd', 18],
      MUSD: ['0x95aEaF383E2e86A47c11CffdE1F7944eCB2C38C2', 18],

      'LION-SVN-LP': ['0xD440433dAA33b3e3f2b5421046EAf84bEe6F40D0', 18],
      'TIGER-SVN-LP': ['0xaDeC6aaAa0765472EE9eBe524BD3454Fd733BAB9', 18],
      'USDC-SVN-LP': ['0xe7bEEC1046007BBCf7394076C654640F32456453', 18],
      'USDC-MMF-LP': ['0x722f19bd9a1e5ba97b3020c6028c279d27e4293c', 18],
      'BEAR-MSHARE-LP': ['0xcC091518302CBca55897Fc370e722b01D57D397a', 18],
      'MMF-SVN-LP': ['0xB6E1705BfAFcf1efEE83C135C0F0210653bAB8F0', 18],
      'LION-USDC-MMF-LP': ['0x32aaaaadecbb11c474ddbd4c934523bcfecea8dd', 18],
      'TIGER-USDC-MMF-LP': ['0x4f61a3104eb671bd501b78fb49ed713803609bc0', 18],
      'BEAR-WBTC-MMF-LP': ['0x8cc728a1a35665e000b303eda9c1d774445e3d12', 18],
      'LION-USDC-LP': ['0xf2059ed015ec4ecc80f902d9fdbcd2a227bfe037', 18],
      'TIGER-USDC-LP': ['0xf6464c80448d6ec4deb7e8e5ec95b8eb768fbf69', 18],
      'BEAR-WBTC-LP': ['0x3d9e539fa44b970605658e25d18f816ce78c4007', 18],
      'DARKCRYSTL-MINE (MMF) LP': ['0xbe4C7893e79028f4956307d2efD2cf8230360c70', 18],
      'DARKCRYSTL-WCRO (MMF) LP': ['0x59505978Dcdb0c820ECf6486AFEB9b2Baa58Ff49', 18],
      'MINE-WCRO (MMF) LP': ['0x1AA703ecBb80271A0d719E4405D030F0fc46c30E', 18],
      'DARKCRYSTL-USDC (MMF) LP': ['0x40EB90721114a0A10eD7732F4dCB74D9672A9FcD', 18],
      'MINE-MUSD (MMF) LP': ['0xB13ae168C893e5bD15f6caAa1D2Da11047e393cA', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 5000,
  },
  137: {
    chainId: 137,
    networkNames: {
      25: 'Cronos Mainnet Beta',
      137: "Polygon Mainnet",
      56: "Binance Smart Chain",
      2222: 'Kava'
    },
    scanUrls: {
      25: 'https://cronoscan.com',
      137: "https://polygonscan.com",
      56: "https://bscscan.com/",
      2222: 'https://explorer.kava.io'
    },
    networkSymbols: {
      25: 'CRO',
      137: "MATIC",
      56: "BNB",
      2222: 'KAVA'

    },
    networkDecimals: {
      25: 18,
      137: 18,
      56: 18,
      2222: 18

    },
    networkUrls: {
      25: 'https://evm.cronos.org',
      137: "https://polygon-rpc.com",
      56: "https://bscrpc.com",
      2222: 'https://evm.kava.io'

    },
    deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WFTM: ['0x654bAc3eC77d6dB497892478f854cF6e8245DcA9', 18],
      USDC: ['0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 6],
      WBTC: ['0x062e66477faf219f25d27dced647bf57c3107d52', 8],
      WCRO: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      MSHARE: ['0xf8b9facB7B4410F5703Eb29093302f2933D6E1Aa', 18],
      MMF: ['0x97749c9b61f878a880dfe312d2594ae07aed7656', 18],
      SVN: ['0x654bac3ec77d6db497892478f854cf6e8245dca9', 18],
      BEAR: ['0xaa22aebd60c9eb653a0ae0cb8b7367087a9b5dba', 18],
      LION: ['0x49fB98F9b4a3183Cd88e7a115144fdf00fa6fB95', 18],
      BBOND: ['0x17f43ea72ddc8f369e3c838c17a7b9f3432a37d7', 18],
      DARKCRYSTL: ['0xefa1fabc2ab6219174ad1c912f56f7de53cdc1e1', 18],
      MINE: ['0x0944d9a8dea16af1170c51e8dd3921c727a06cbd', 18],
      MUSD: ['0x95aEaF383E2e86A47c11CffdE1F7944eCB2C38C2', 18],

      'LION-SVN-LP': ['0xD440433dAA33b3e3f2b5421046EAf84bEe6F40D0', 18],
      'TIGER-SVN-LP': ['0xaDeC6aaAa0765472EE9eBe524BD3454Fd733BAB9', 18],
      'USDC-SVN-LP': ['0xe7bEEC1046007BBCf7394076C654640F32456453', 18],
      'USDC-MMF-LP': ['0x722f19bd9a1e5ba97b3020c6028c279d27e4293c', 18],
      'BEAR-MSHARE-LP': ['0xcC091518302CBca55897Fc370e722b01D57D397a', 18],
      'MMF-SVN-LP': ['0xB6E1705BfAFcf1efEE83C135C0F0210653bAB8F0', 18],
      'LION-USDC-MMF-LP': ['0x32aaaaadecbb11c474ddbd4c934523bcfecea8dd', 18],
      'TIGER-USDC-MMF-LP': ['0x4f61a3104eb671bd501b78fb49ed713803609bc0', 18],
      'BEAR-WBTC-MMF-LP': ['0x8cc728a1a35665e000b303eda9c1d774445e3d12', 18],
      'LION-USDC-LP': ['0xf2059ed015ec4ecc80f902d9fdbcd2a227bfe037', 18],
      'TIGER-USDC-LP': ['0xf6464c80448d6ec4deb7e8e5ec95b8eb768fbf69', 18],
      'BEAR-WBTC-LP': ['0x3d9e539fa44b970605658e25d18f816ce78c4007', 18],
      'DARKCRYSTL-MINE (MMF) LP': ['0xbe4C7893e79028f4956307d2efD2cf8230360c70', 18],
      'DARKCRYSTL-WCRO (MMF) LP': ['0x59505978Dcdb0c820ECf6486AFEB9b2Baa58Ff49', 18],
      'MINE-WCRO (MMF) LP': ['0x1AA703ecBb80271A0d719E4405D030F0fc46c30E', 18],
      'DARKCRYSTL-USDC (MMF) LP': ['0x40EB90721114a0A10eD7732F4dCB74D9672A9FcD', 18],
      'MINE-MUSD (MMF) LP': ['0xB13ae168C893e5bD15f6caAa1D2Da11047e393cA', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 5000,
  },*/
  2222: {
    chainId: 2222,
    networkNames: {
      25: 'Cronos Mainnet Beta',
      /*137: "Polygon Mainnet",
      56: "Binance Smart Chain",*/
      2222: 'Kava',
    },
    scanUrls: {
      25: 'https://cronoscan.com',
      /*137: "https://polygonscan.com",
      56: "https://bscscan.com/",*/
      2222: 'https://explorer.kava.io',
    },
    networkSymbols: {
      25: 'CRO',
      /*137: "MATIC",
      56: "BNB",*/
      2222: 'KAVA',
    },
    networkDecimals: {
      25: 18,
      /*137: 18,
      56: 18,*/
      2222: 18,
    },
    networkUrls: {
      25: 'https://evm.cronos.org',
      /*137: "https://polygon-rpc.com",
      56: "https://bscrpc.com",*/
      2222: 'https://evm.kava.io',
    },
    deployments: require('./tomb-finance/deployments/deployments.testing.kava.json'),
    externalTokens: {
      WFTM: ['0x990e157fC8a492c28F5B50022F000183131b9026', 18],
      USDC: ['0xfA9343C3897324496A05fC75abeD6bAC29f8A40f', 6],
      USDT: ['0x919C1c267BC06a7039e03fcc2eF738525769109c', 6],
      WBTC: ['0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b', 8],
      BEAR: ['0x38481Fdc1aF61E6E72E0Ff46F069315A59779C65', 18],
      LION: ['0x990e157fC8a492c28F5B50022F000183131b9026', 18],
      TIGER: ['0x471F79616569343e8e84a66F342B7B433b958154', 18],
      'LION-USDC-LP': ['0x59e38a5799B64fE17c5fAb7E0E5396C15E2acb7b', 18],
      'TIGER-USDC-LP': ['0x6Eff7d2D494bc13949523e3504dE1994a6325F0A', 18],
      'BEAR-WBTC-LP': ['0x9e334ce82f7659d2967C92a4a399aD694F63bbCF', 18],
      'LION-USDT-LP': ['0xa3c6a2f6d67354f43A0b5eC9E46467822750968C', 18],
      'TIGER-LION-LP': ['0x78Ef6D3E3d0da9B2248C11BE11743B4C573ADd25', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 5000,
  },
};

export const bankDefinitions: { [chainId: string]: { [contractName: string]: BankInfo } } = {
  25: {
    /*
    Explanation:
    name: description of the card
    poolId: the poolId assigned in the contract
    sectionInUI: way to distinguish in which of the 4 pool groups it should be listed
          - 0 = Auto Compounders
          - 1 = Deprecated LP asset staking rewarding TIGER
          - 2 = Deprecated Tiger auto compounders
          - 3 = External auto compounders
    contract: the contract name which will be loaded from the deployment.environmnet.json
    depositTokenName : the name of the token to be deposited
    earnTokenName: the rewarded token
    finished: will disable the pool on the UI if set to true
    sort: the order of the pool
    */
    LionSvnLPTigerRewardPool: {
      name: 'Deposit LION-SVN-LP',
      poolId: 0,
      sectionInUI: 1,
      displayImage: lionLogo,
      rewardsText: 'Earn TIGER',
      contract: 'LionSvnLPTigerRewardPool',
      farmContract: 'LionSvnLPTigerRewardPool',
      depositTokenName: 'LION-SVN-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 1,
      closedForStaking: true,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    TigerSvnLPTigerRewardPool: {
      name: 'Deposit Tiger-SVN-LP',
      poolId: 1,
      sectionInUI: 1,
      displayImage: tigerLogo,
      rewardsText: 'Earn TIGER',
      contract: 'TigerSvnLPTigerRewardPool',
      farmContract: 'TigerSvnLPTigerRewardPool',
      depositTokenName: 'TIGER-SVN-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: true,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
    },
    BearMshareLPTigerRewardPool: {
      name: 'Deposit Bear-MSHARE-LP',
      poolId: 2,
      sectionInUI: 1,
      displayImage: bearLogo,
      rewardsText: 'Earn TIGER',
      contract: 'BearMshareLPTigerRewardPool',
      farmContract: 'BearMshareLPTigerRewardPool',
      depositTokenName: 'BEAR-MSHARE-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: true,
      tokenSymbol: bearLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionSvnLPVault: {
      name: 'Autocompound LION-SVN-LP',
      poolId: 3,
      sectionInUI: 2,
      rewardsText: 'LION Buy Back: 5%',
      displayImage: lionLogo,
      contract: 'LionSvnLPVault',
      farmContract: 'LionSvnLPTigerRewardPool',
      depositTokenName: 'LION-SVN-LP',
      earnTokenName: 'LION-SVN-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Lion',
      finished: false,
      sort: 3,
      closedForStaking: true,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    TigerSvnLPVault: {
      name: 'Autocompound TIGER-SVN LP',
      poolId: 5,
      sectionInUI: 2,
      displayImage: tigerLogo,
      rewardsText: 'Treasury CRO Fees: 5%',
      contract: 'TigerSvnLPVault',
      farmContract: 'TigerSvnLPTigerRewardPool',
      depositTokenName: 'TIGER-SVN-LP',
      earnTokenName: 'TIGER-SVN-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Cro',
      finished: false,
      sort: 4,
      closedForStaking: true,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
    },
    BearMshareLPVault: {
      name: 'Autocompound BEAR-MSHARE LP',
      poolId: 4,
      sectionInUI: 2,
      displayImage: bearLogo,
      rewardsText: 'BEAR Buy Back: 5%',
      contract: 'BearMshareLPVault',
      farmContract: 'BearMshareLPTigerRewardPool',
      depositTokenName: 'BEAR-MSHARE-LP',
      earnTokenName: 'BEAR-MSHARE-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Bear',
      finished: false,
      sort: 5,
      closedForStaking: true,
      tokenSymbol: bearLogo,
      earnedTokenSymbol: tigerLogo,
    },
    MMFSVNLPVault: {
      name: 'Autocompound MMF-SVN LP',
      poolId: 7,
      sectionInUI: 3,
      displayImage: lionLogo,
      rewardsText: 'LION Buy Back: 5%',
      contract: 'MMFSVNLPVault',
      farmContract: 'MMFSVNLPRewardPool',
      depositTokenName: 'MMF-SVN-LP',
      earnTokenName: 'MMF-SVN-LP',
      farmEarnTokenName: 'MSHARE',
      buyBackTokenName: 'Lion',
      finished: false,
      sort: 5,
      closedForStaking: true,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionUsdcMMFLPTigerRewardPool: {
      name: 'Earn TIGER by LION-USDC LP',
      poolId: 3,
      sectionInUI: 4,
      displayImage: lionLogo,
      contract: 'LionUsdcMMFLPTigerRewardPool',
      farmContract: 'LionUsdcLPTigerRewardPool',
      depositTokenName: 'LION-USDC-MMF-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 1,
      closedForStaking: true,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    TigerUsdcMMFLPTigerRewardPool: {
      name: 'Earn TIGER by TIGER-USDC LP',
      poolId: 4,
      sectionInUI: 4,
      displayImage: tigerLogo,
      contract: 'TigerUsdcMMFLPTigerRewardPool',
      farmContract: 'TigerUsdcLPTigerRewardPool',
      depositTokenName: 'TIGER-USDC-MMF-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: true,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
    },
    BearBtcMMFLPTigerRewardPool: {
      name: 'Earn TIGER by BEAR-WBTC LP',
      poolId: 5,
      sectionInUI: 4,
      displayImage: bearLogo,
      contract: 'BearBtcMMFLPTigerRewardPool',
      farmContract: 'BearBtcLPTigerRewardPool',
      depositTokenName: 'BEAR-WBTC-MMF-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: true,
      tokenSymbol: bearLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionUsdcMMFLPVault: {
      name: 'Autocompound LION-USDC-LP',
      poolId: 1,
      sectionInUI: 0,
      displayImage: lionLogo,
      rewardsText: 'LION Buy Back: 5%',
      contract: 'LionUsdcMMFLPVault',
      farmContract: 'LionUsdcLPTigerRewardPool',
      depositTokenName: 'LION-USDC-MMF-LP',
      earnTokenName: 'LION-USDC-MMF-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Lion',
      finished: false,
      sort: 3,
      closedForStaking: true,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    TigerUsdcMMFLPVault: {
      name: 'Autocompound TIGER-USDC-LP',
      poolId: 2,
      sectionInUI: 0,
      rewardsText: 'Treasury CRO Fees: 5%',
      displayImage: tigerLogo,
      contract: 'TigerUsdcMMFLPVault',
      farmContract: 'TigerUsdcLPTigerRewardPool',
      depositTokenName: 'TIGER-USDC-MMF-LP',
      earnTokenName: 'TIGER-USDC-MMF-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Cro',
      finished: false,
      sort: 4,
      closedForStaking: true,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
    },
    BearWBTCMMFLPVault: {
      name: 'Autocompound BEAR-WBTC-LP',
      poolId: 3,
      sectionInUI: 0,
      displayImage: bearLogo,
      rewardsText: 'BEAR Buy Back: 5%',
      contract: 'BearBtcMMFLPVault',
      farmContract: 'BearBtcLPTigerRewardPool',
      depositTokenName: 'BEAR-WBTC-MMF-LP',
      earnTokenName: 'BEAR-WBTC-MMF-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Bear',
      finished: false,
      sort: 5,
      closedForStaking: true,
      tokenSymbol: bearLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionUsdcLPVault: {
      name: 'Autocompound LION-USDC-LP',
      poolId: 4,
      sectionInUI: 5,
      displayImage: lionLogo,
      rewardsText: 'LION Buy Back: 5%',
      contract: 'LionUsdcLPVault',
      farmContract: 'LionUsdcLPTigerRewardPool',
      depositTokenName: 'LION-USDC-LP',
      earnTokenName: 'LION-USDC-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Lion',
      finished: false,
      sort: 3,
      closedForStaking: true,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
      migrateFrom: 'LION-USDC-MMF-LP',
    },
    TigerUsdcLPVault: {
      name: 'Autocompound TIGER-USDC-LP',
      poolId: 5,
      sectionInUI: 5,
      rewardsText: 'Treasury CRO Fees: 5%',
      displayImage: tigerLogo,
      contract: 'TigerUsdcLPVault',
      farmContract: 'TigerUsdcLPTigerRewardPool',
      depositTokenName: 'TIGER-USDC-LP',
      earnTokenName: 'TIGER-USDC-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Cro',
      finished: false,
      sort: 4,
      closedForStaking: true,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
      migrateFrom: 'TIGER-USDC-MMF-LP',
    },
    BearWBTCLPVault: {
      name: 'Autocompound BEAR-WBTC-LP',
      poolId: 6,
      sectionInUI: 5,
      displayImage: bearLogo,
      rewardsText: 'BEAR Buy Back: 5%',
      contract: 'BearBtcLPVault',
      farmContract: 'BearBtcLPTigerRewardPool',
      depositTokenName: 'BEAR-WBTC-LP',
      earnTokenName: 'BEAR-WBTC-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Bear',
      finished: false,
      sort: 5,
      closedForStaking: true,
      tokenSymbol: bearLogo,
      earnedTokenSymbol: tigerLogo,
      migrateFrom: 'BEAR-WBTC-MMF-LP',
    },
    LionUsdcLPTigerRewardPool: {
      name: 'Earn TIGER by LION-USDC LP',
      poolId: 6,
      sectionInUI: 6,
      displayImage: lionLogo,
      contract: 'LionUsdcLPTigerRewardPool',
      farmContract: 'LionUsdcLPTigerRewardPool',
      depositTokenName: 'LION-USDC-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 1,
      closedForStaking: true,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
      migrateFrom: 'LION-USDC-MMF-LP',
    },
    TigerUsdcLPTigerRewardPool: {
      name: 'Earn TIGER by TIGER-USDC LP',
      poolId: 7,
      sectionInUI: 6,
      displayImage: tigerLogo,
      contract: 'TigerUsdcLPTigerRewardPool',
      farmContract: 'TigerUsdcLPTigerRewardPool',
      depositTokenName: 'TIGER-USDC-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: true,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
      migrateFrom: 'TIGER-USDC-MMF-LP',
    },
    BearBtcLPTigerRewardPool: {
      name: 'Earn TIGER by BEAR-WBTC LP',
      poolId: 8,
      sectionInUI: 6,
      displayImage: bearLogo,
      contract: 'BearBtcLPTigerRewardPool',
      farmContract: 'BearBtcLPTigerRewardPool',
      depositTokenName: 'BEAR-WBTC-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: true,
      tokenSymbol: bearLogo,
      earnedTokenSymbol: tigerLogo,
      migrateFrom: 'BEAR-WBTC-MMF-LP',
    },
    DarkcMineLPVault: {
      name: 'Autocompound DARKCRYSTL-MINE-LP',
      poolId: 8,
      sectionInUI: 7,
      displayImage: darkMineLogo,
      rewardsText: 'WCRO Buy Back: 5%',
      contract: 'DarkcMineLPVault',
      farmContract: 'MineRewardPool',
      depositTokenName: 'DARKCRYSTL-MINE (MMF) LP',
      earnTokenName: 'DARKCRYSTL-MINE (MMF) LP',
      farmEarnTokenName: 'MINE',
      buyBackTokenName: 'WCRO',
      finished: false,
      sort: 3,
      closedForStaking: false,
      tokenSymbol: darkMineLogo,
      earnedTokenSymbol: mineLogo,
    },
    DarkcWCroLPVault: {
      name: 'Autocompound DARKCRYSTL-WCRO-LP',
      poolId: 9,
      sectionInUI: 7,
      displayImage: darkCroLogo,
      rewardsText: 'WCRO Buy Back: 5%',
      contract: 'DarkcWCroLPVault',
      farmContract: 'MineRewardPool',
      depositTokenName: 'DARKCRYSTL-WCRO (MMF) LP',
      earnTokenName: 'DARKCRYSTL-WCRO (MMF) LP',
      farmEarnTokenName: 'MINE',
      buyBackTokenName: 'WCRO',
      finished: false,
      sort: 1,
      closedForStaking: false,
      tokenSymbol: darkCroLogo,
      earnedTokenSymbol: mineLogo,
    },
    MineWCroLPVault: {
      name: 'Autocompound MINE-WCRO-LP',
      poolId: 10,
      sectionInUI: 7,
      displayImage: mineCroLogo,
      rewardsText: 'WCRO Buy Back: 5%',
      contract: 'MineWCroLPVault',
      farmContract: 'MineRewardPool',
      depositTokenName: 'MINE-WCRO (MMF) LP',
      earnTokenName: 'MINE-WCRO (MMF) LP',
      farmEarnTokenName: 'MINE',
      buyBackTokenName: 'WCRO',
      finished: false,
      sort: 2,
      closedForStaking: false,
      tokenSymbol: mineCroLogo,
      earnedTokenSymbol: mineLogo,
    },
    DarkcUsdcLPVault: {
      name: 'Autocompound DARKC-USDC-LP',
      poolId: 11,
      sectionInUI: 7,
      displayImage: darkUsdcLogo,
      rewardsText: 'WCRO Buy Back: 5%',
      contract: 'DarkcUsdcLPVault',
      farmContract: 'MineRewardPool',
      depositTokenName: 'DARKC-USDC (MMF) LP',
      earnTokenName: 'DARKC-USDC (MMF) LP',
      farmEarnTokenName: 'MINE',
      buyBackTokenName: 'WCRO',
      finished: false,
      sort: 4,
      closedForStaking: false,
      tokenSymbol: darkUsdcLogo,
      earnedTokenSymbol: mineLogo,
    },
    MineMusdLPVault: {
      name: 'Autocompound MINE-MUSD-LP',
      poolId: 12,
      sectionInUI: 7,
      displayImage: mineMusdLogo,
      rewardsText: 'WCRO Buy Back: 5%',
      contract: 'MineMusdLPVault',
      farmContract: 'MineRewardPool',
      depositTokenName: 'MINE-MUSD (MMF) LP',
      earnTokenName: 'MINE-MUSD (MMF) LP',
      farmEarnTokenName: 'MINE',
      buyBackTokenName: 'WCRO',
      finished: false,
      sort: 5,
      closedForStaking: false,
      tokenSymbol: mineMusdLogo,
      earnedTokenSymbol: mineLogo,
    },
  },
  56: {},
  137: {},
  2222: {
    LionTigerRewardPoolLockedNewMarch: {
      name: 'Earn TIGER with LION - 60 days NEW',
      poolId: 0,
      sectionInUI: 8,
      displayImage: lionLogo,
      contract: 'LionTigerRewardPoolLockedNewMarch',
      farmContract: 'LionTigerRewardPoolLockedNewMarch',
      depositTokenName: 'LION',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      lockedPool: true,
      unlockEpoch: 1678190400,
      sort: 1,
      closedForStaking: false,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionTigerRewardPoolLockedNew: {
      name: 'Earn TIGER with LION - 90 days - OLD',
      poolId: 0,
      sectionInUI: 8,
      displayImage: lionLogo,
      contract: 'LionTigerRewardPoolLockedNew',
      farmContract: 'LionTigerRewardPoolLockedNew',
      depositTokenName: 'LION',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      lockedPool: true,
      unlockEpoch: 1672574400,
      sort: 1,
      closedForStaking: false,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionTigerRewardPoolLocked: {
      name: 'Earn TIGER with LION - 90 days - OLD',
      poolId: 0,
      sectionInUI: 8,
      displayImage: lionLogo,
      contract: 'LionTigerRewardPoolLocked',
      farmContract: 'LionTigerRewardPoolLocked',
      depositTokenName: 'LION',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      lockedPool: true,
      unlockEpoch: 1669046400,
      sort: 1,
      closedForStaking: false,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionUsdcLPTigerRewardPool: {
      name: 'Earn TIGER by LION-USDC LP',
      poolId: 0,
      sectionInUI: 6,
      displayImage: lionLogo,
      contract: 'LionUsdcLPTigerRewardPool',
      farmContract: 'LionUsdcLPTigerRewardPool',
      depositTokenName: 'LION-USDC-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 1,
      closedForStaking: false,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    TigerUsdcLPTigerRewardPool: {
      name: 'Earn TIGER by TIGER-USDC LP',
      poolId: 1,
      sectionInUI: 6,
      displayImage: tigerLogo,
      contract: 'TigerUsdcLPTigerRewardPool',
      farmContract: 'TigerUsdcLPTigerRewardPool',
      depositTokenName: 'TIGER-USDC-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: false,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
    },
    BearBtcLPTigerRewardPool: {
      name: 'Earn TIGER by BEAR-WBTC LP',
      poolId: 2,
      sectionInUI: 6,
      displayImage: bearLogo,
      contract: 'BearBtcLPTigerRewardPool',
      farmContract: 'BearBtcLPTigerRewardPool',
      depositTokenName: 'BEAR-WBTC-LP',
      earnTokenName: 'TIGER',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: '',
      finished: false,
      sort: 2,
      closedForStaking: false,
      tokenSymbol: bearLogo,
      earnedTokenSymbol: tigerLogo,
    },
    LionUsdcLPVault: {
      name: 'Autocompound LION-USDC-LP',
      poolId: 0,
      sectionInUI: 5,
      displayImage: lionLogo,
      rewardsText: 'LION Buy Back: 5%',
      contract: 'LionUsdcLPVault',
      farmContract: 'LionUsdcLPTigerRewardPool',
      depositTokenName: 'LION-USDC-LP',
      earnTokenName: 'LION-USDC-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'Lion',
      finished: false,
      sort: 3,
      closedForStaking: false,
      tokenSymbol: lionLogo,
      earnedTokenSymbol: tigerLogo,
    },
    TigerUsdcLPVault: {
      name: 'Autocompound TIGER-USDC-LP',
      poolId: 2,
      sectionInUI: 5,
      rewardsText: 'Tiger Buyback: 5%',
      displayImage: tigerLogo,
      contract: 'TigerUsdcLPVault',
      farmContract: 'TigerUsdcLPTigerRewardPool',
      depositTokenName: 'TIGER-USDC-LP',
      earnTokenName: 'TIGER-USDC-LP',
      farmEarnTokenName: 'TIGER',
      buyBackTokenName: 'TIGER',
      finished: false,
      sort: 4,
      closedForStaking: false,
      tokenSymbol: tigerLogo,
      earnedTokenSymbol: tigerLogo,
    },
  },
};

export default configurations;

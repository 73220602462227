import { useContext } from 'react';

import { Context } from '../contexts/ChainIdProvider';

const useChainId = () => {
  const context = useContext(Context);
  return context;
};

export default useChainId;




import { BigNumber } from 'ethers';
import { useCallback, useEffect, useState } from 'react';

import config from '../config';
import ERC20 from '../tomb-finance/ERC20';
import useChainId from './useChainId';
import useTombFinance from './useTombFinance';

const useTokenBalance = (token: ERC20) => {
  const [balance, setBalance] = useState(BigNumber.from(0));
  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;
  const {chainId} = useChainId()

  const fetchBalance = useCallback(async () => {
    if (!token) return setBalance(BigNumber.from(0));
    setBalance(await token.balanceOf(tombFinance.myAccount));
  }, [token, tombFinance.myAccount]);

  useEffect(() => {
    if (isUnlocked) {
      fetchBalance().catch((err) => console.error(`Failed to fetch token balance: ${err.stack}`));
      let refreshInterval = setInterval(fetchBalance, config[chainId]?.refreshInterval);
      return () => clearInterval(refreshInterval);
    }
  }, [isUnlocked, token, fetchBalance, tombFinance]);

  return balance;
};

export default useTokenBalance;

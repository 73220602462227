import walletCon from 'assets/img/wallet/walletconnect-logo.svg';
import React from 'react';
import { MdClose } from 'react-icons/md';
import CustomBtn from 'uikit/BtnComponent';
import { useWallet } from 'use-wallet';
import { noop } from 'utils/utils';

import {
  Btn,
  CloseBtn,
  FooterPart,
  FooterText,
  Item,
  ItemImg,
  ItemNumber,
  Items,
  WalletTitle,
  ZapBack,
  ZapModal,
  ZapModalBody,
} from './connect.style';

export default function ConnectModal({ showConnect, setShowConnect }) {
  const { connect } = useWallet();

  if (!showConnect) {
    return <></>;
  }

  return (
    <ZapModal>
      <ZapModalBody>
        <CloseBtn>
          <MdClose onClick={() => setShowConnect(false)} className="closeclass" />
        </CloseBtn>
        <WalletTitle>Connect Wallet</WalletTitle>
        <Items>
          <Item>
            <ItemImg
              onClick={() => {
                connect('injected');
                setShowConnect(false);
              }}
              src="img/metamask.png"
              alt=""
            />
            <ItemNumber>Metamask</ItemNumber>
          </Item>
          <Item>
            <ItemImg
              onClick={() => {
                connect('walletconnect');
                setShowConnect(false);
              }}
              src={walletCon}
              alt=""
            />
            <ItemNumber>Wallet Connect</ItemNumber>
          </Item>
        </Items>
        <FooterPart>
          <FooterText>Haven't got a crypto wallet yet?</FooterText>
          <Btn>
            <a
              href="https://scrub.gitbook.io/scrub-doc/cronos-chain/bridging-funds-onto-cronos-mainnet"
              rel="noopener noreferrer"
              target="_blank"
            >
              <CustomBtn width="254px" height="50px" fontsize="16px" text="LEARN HOW TO CONNECT" clickFunc={noop} />
            </a>
          </Btn>
        </FooterPart>
      </ZapModalBody>
      <ZapBack onClick={() => setShowConnect(false)} />
    </ZapModal>
  );
}

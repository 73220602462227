import useTokenBalance from 'hooks/useTokenBalance';
import useTombFinance from 'hooks/useTombFinance';
import React, { useMemo } from 'react';
import { MdClose } from 'react-icons/md';
import CustomBtn from 'uikit/BtnComponent';
import { useWallet } from 'use-wallet';
import { getDisplayBalance } from 'utils/formatBalance';
import bearLogo from 'assets/tokenImg/bear.png';
import lionLogo from 'assets/tokenImg/lion.png';
import tigerLogo from 'assets/tokenImg/tiger.png';
import { maskAccount } from '../../utils/utils';
import {
  CloseBtn,
  Item,
  ItemImg,
  ItemName,
  ItemNumber,
  Items,
  WalletAddress,
  WalletFooter,
  WalletTitle,
  ZapBack,
  ZapModal,
  ZapModalBody,
} from './header.style';

export default function WalletModal({ setWalletFlag }) {
  const { reset, account } = useWallet();
  const maskedAccount = account ? maskAccount(account) : 'MY WALLET';
  const tombFinance = useTombFinance();

  const resetLogin = () => {
    localStorage.setItem('connectorId', '');
    // There is no way for a proper use-wallet disconnect - the user has to disconnect it from the wallet app directly.
    // We change directly the values stored by use-wallet, this will prompt the user to reconnect again for walletconnect.
    localStorage.setItem('walletconnect', '');
    setWalletFlag(false);
    reset();
  };

  const tombBalance = useTokenBalance(tombFinance.TOMB);
  const displayTombBalance = useMemo(() => getDisplayBalance(tombBalance), [tombBalance]);

  const tshareBalance = useTokenBalance(tombFinance.TSHARE);
  const displayTshareBalance = useMemo(() => getDisplayBalance(tshareBalance), [tshareBalance]);

  const bearBalance = useTokenBalance(tombFinance.externalTokens.BEAR);
  const displayBearBalance = useMemo(() => getDisplayBalance(bearBalance), [bearBalance]);
  console.log('bear balance:', displayBearBalance);

  return (
    <ZapModal>
      <ZapModalBody>
        <CloseBtn>
          <MdClose onClick={() => setWalletFlag(false)} className="closeclass" />
        </CloseBtn>
        <WalletTitle>My Wallet</WalletTitle>
        <WalletAddress>{maskedAccount}</WalletAddress>
        <Items>
          <Item>
            <ItemImg src={lionLogo} alt="" />
            <ItemNumber>{displayTombBalance}</ItemNumber>
            <ItemName>LION Avaliable</ItemName>
          </Item>
          <Item>
            <ItemImg src={tigerLogo} alt="" />
            <ItemNumber>{displayTshareBalance}</ItemNumber>
            <ItemName>TIGER Avaliable</ItemName>
          </Item>
          <Item>
            <ItemImg src={bearLogo} alt="" />
            <ItemNumber>{displayBearBalance}</ItemNumber>
            <ItemName>BEAR Avaliable</ItemName>
          </Item>
        </Items>
        <WalletFooter>
          <CustomBtn width="254px" height="50px" fontsize="16px" text="Disconnect" clickFunc={resetLogin}></CustomBtn>
        </WalletFooter>
      </ZapModalBody>
      <ZapBack onClick={() => setWalletFlag(false)} />
    </ZapModal>
  );
}

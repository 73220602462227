import BigNumber from 'bignumber.js';
import { Contract } from 'ethers';
import Web3 from 'web3';

import { Provider } from '@ethersproject/abstract-provider';

import ERC20ABI from '../tomb-finance/ERC20.abi.json';
import IUniswapV2PairABI from '../tomb-finance/IUniswapV2Pair.abi.json';

// const DEFAULT_CONTRACT_PROVIDER = new Web3.providers.WebsocketProvider(getWSRpcUrl());
const DEFAULT_CONTRACT_PROVIDER = new Web3.providers.HttpProvider(getHttpsRpcUrl());
// export const web3 = new Web3(DEFAULT_CONTRACT_PROVIDER);

// @ts-ignore: Unreachable code error
export const web3 = new Web3(DEFAULT_CONTRACT_PROVIDER);
export const MAX_UINT_256 = new BigNumber(2).pow(256).minus(1);
export const ZERO_BIG_NUMBER = new BigNumber(0);

export function getWSRpcUrl(chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID)): string {
  const WEB3_RPC_ID = String(process.env.REACT_APP_WEB3_RPC_ID);
  switch (chainId) {
    case 1:
      return `wss://mainnet.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 3:
      return `wss://ropsten.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 4:
      return `wss://rinkeby.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 42:
      return `wss://kovan.infura.io/ws/v3/${WEB3_RPC_ID}`;
    case 56:
      return `wss://bsc-dataseed.binance.org/`;
    case 137:
      return `wss://rpc-mainnet.matic.quiknode.pro`;
    case 25:
      return `wss://evm.cronos.org`;
    case 2222:
      return `wss://evm.kava.io`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getHttpsRpcUrl(chainId: number = Number(25)): string {
  const WEB3_RPC_ID = String(chainId);

  switch (chainId) {
    case 1:
      return `https://mainnet.infura.io/v3/${WEB3_RPC_ID}`;
    case 3:
      return `https://ropsten.infura.io/v3/${WEB3_RPC_ID}`;
    case 4:
      return `https://rinkeby.infura.io/v3/${WEB3_RPC_ID}`;
    case 42:
      return `https://kovan.infura.io/v3/${WEB3_RPC_ID}`;
    case 56:
      return `https://bsc-dataseed.binance.org/`;
    case 137:
      return `https://rpc-mainnet.matic.quiknode.pro`;
    case 25:
      return `https://rpc.nebkas.ro`;
    case 2222:
      return `https://evm.kava.io`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getBtcerscanTxUrl(
  txHash: string,
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID),
): string {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/tx/${txHash}`;
    case 3:
      return `https://ropsten.etherscan.io/tx/${txHash}`;
    case 4:
      return `https://rinkeby.etherscan.io/tx/${txHash}`;
    case 42:
      return `https://kovan.etherscan.io/tx/${txHash}`;
    case 56:
      return `https://bscscan.com/tx/${txHash}`;
    case 137:
      return `https://polygonscan.com/tx/${txHash}`;
    case 25:
      return `https://cronoscan.com/tx/${txHash}`;
    case 2222:
      return `https://explorer.kava.io/tx/${txHash}`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getBtcerscanAddressUrl(
  address: string,
  chainId: number = Number(process.env.REACT_APP_WEB3_CHAIN_ID),
): string {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/address/${address}`;
    case 3:
      return `https://ropsten.etherscan.io/address/${address}`;
    case 4:
      return `https://rinkeby.etherscan.io/address/${address}`;
    case 42:
      return `https://kovan.etherscan.io/address/${address}`;
    case 56:
      return `https://bscscan.com/address/${address}`;
    case 137:
      return `https://polygonscan.com/address/${address}`;
    case 25:
      return `https://cronoscan.com/address/${address}`;
    case 2222:
      return `https://explorer.kava.io/address/${address}`;
    default:
      throw new Error(`Not supported chainId=${chainId}.`);
  }
}

export function getNetworkName(chainId: number | undefined): string {
  switch (chainId) {
    case 1:
      return 'Btcereum Mainnet';
    case 3:
      return 'Ropsten';
    case 4:
      return 'Rinkeby';
    case 42:
      return 'kovan';
    case 56:
      return 'BSC Mainnet';
    case 137:
      return 'Polygon';
    case 25:
      return 'Cronos';
    case 2222:
      return 'Kava';
    default:
      return '-';
  }
}

export function getExponentValue(decimals: number = 0): BigNumber {
  return new BigNumber(10).pow(decimals);
}

export function getHumanValue(value?: BigNumber, decimals: number = 0): BigNumber | undefined {
  return value?.div(getExponentValue(decimals));
}

export function getNonHumanValue(value: BigNumber | number, decimals: number = 0): BigNumber {
  return new BigNumber(value).multipliedBy(getExponentValue(decimals));
}

export function formatBigValue(
  value?: BigNumber,
  decimals: number = 4,
  defaultValue: string = '-',
  minDecimals: number | undefined = undefined,
): string {
  return value ? new BigNumber(value.toFixed(decimals)).toFormat(minDecimals) : defaultValue;
}

export function formatUSDValue(value?: BigNumber, decimals: number = 2, minDecimals: number = decimals): string {
  if (value === undefined) {
    return '-';
  }

  const val = BigNumber.isBigNumber(value) ? value : new BigNumber(value);
  const formattedValue = formatBigValue(val.abs(), decimals, '-', minDecimals);

  return val.isPositive() ? `$${formattedValue}` : `-$${formattedValue}`;
}

export function defaultFormatValue(value?: BigNumber): string {
  return formatBigValue(value, 2);
}

export function shortenAddr(addr: string, first: number = 6, last: number = 4) {
  return [String(addr).slice(0, first), String(addr).slice(-last)].join('...');
}

export function convertToNumber(value: BigNumber | undefined) {
  if (value) {
    return parseFloat(value.toString());
  }

  return 0;
}

/**
 * Takes in a wallet address and returns first 4 plus the last 4 chars of it,
 * in order to keep privacy.
 *
 * Used to display the masked wallet address on the header and connect modal.
 *
 * @param account {string} - usually a wallet address
 */
export const maskAccount = (account: string): string =>
  account.substring(0, 4) + '...' + account.substring(account.length - 1 - 4, account.length);

/**
 * Returns an ERC20 contract for the given address.
 *
 * @param address {string} The address to get the contract from.
 * @param provider {Provider} The current provider
 *
 * @returns Contract The requested contract.
 */
export const getERC20FromAddress = (address: string, provider: Provider) => {
  return new Contract(address, ERC20ABI, provider);
};

/**
 * Returns an UniswapPair contract for the given address.
 *
 * @param address {string} The address to get the contract from.
 * @param provider {Provider} The current provider
 *
 * @returns Contract The requested contract.
 */
export const getPairFromAddress = (address: string, provider: Provider) => {
  return new Contract(address, IUniswapV2PairABI, provider);
};

/**
 * Nothing, just an empty function.
 */
export const noop = () => { };

import { Deployments } from './deployments';

export type Configuration = {
  chainId: string | number;
  networkNames: { [chainId: string]: string };
  scanUrls: { [chainId: string]: string };
  networkSymbols: { [chainId: string]: string };
  networkDecimals: { [chainId: string]: number };
  networkUrls: { [chainId: string]: string };
  deployments: Deployments;
  externalTokens: { [contractName: string]: [string, number] };
  config?: EthereumConfig;

  baseLaunchDate: Date;
  bondLaunchesAt: Date;
  masonryLaunchesAt: Date;

  refreshInterval: number;
};

export type EthereumConfig = {
  testing: boolean;
  autoGasMultiplier: number;
  defaultConfirmations: number;
  defaultGas: string;
  defaultGasPrice: string;
  ethereumNodeTimeout: number;
};

export const defaultEthereumConfig = {
  testing: false,
  autoGasMultiplier: 1.5,
  defaultConfirmations: 1,
  defaultGas: '6000000',
  defaultGasPrice: '1000000000000',
  ethereumNodeTimeout: 10000,
};

export const WHITELISTED_TOKENS = [
  {
    name: 'LION',
    symbol: 'L',
    image: '/lion.png',
    decimals: 18,
    address: '0x990e157fC8a492c28F5B50022F000183131b9026',
    active: true,
    lp: false,
  },
  {
    name: 'TIGER',
    symbol: 'T',
    image: '/tiger.png',
    decimals: 18,
    address: '0x471F79616569343e8e84a66F342B7B433b958154',
    active: true,
    lp: false,
  },
  {
    name: 'BEAR',
    symbol: 'B',
    image: '/bear.png',
    decimals: 18,
    address: '0x38481Fdc1aF61E6E72E0Ff46F069315A59779C65',
    active: true,
    lp: false,
  },
  {
    name: 'USDt',
    symbol: '$',
    image: '/usdt.png',
    decimals: 6,
    address: '0x919C1c267BC06a7039e03fcc2eF738525769109c',
    active: true,
    maxBorrow: 85,
    lp: false,
  },
  {
    name: 'multiUSDC',
    symbol: '$',
    image: '/multiUSDC.png',
    decimals: 6,
    address: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    active: true,
    deprecated: true,
    maxBorrow: 80,
    lp: false,
  },
  {
    name: 'axlUSDC',
    symbol: '$',
    image: '/axlusdc.png',
    decimals: 6,
    address: '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
    active: true,
    lp: false,
  },
  {
    name: 'axlUSDT',
    symbol: '$',
    image: '/axlusdt.png',
    decimals: 6,
    address: '0x7f5373AE26c3E8FfC4c77b7255DF7eC1A9aF52a6',
    active: true,
  },
  {
    name: 'multiUSDT',
    symbol: '$',
    image: '/multiUSDT.png',
    decimals: 6,
    address: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    active: true,
    deprecated: true,
    maxBorrow: 80,
    lp: false,
  },
  {
    name: 'multiDAI',
    symbol: '$',
    image: '/multiDAI.png',
    decimals: 18,
    address: '0x765277eebeca2e31912c9946eae1021199b39c61',
    active: true,
    deprecated: true,
    maxBorrow: 80,
    lp: false,
  },
  {
    name: 'WKAVA',
    image: '/wkava.png',
    symbol: 'K',
    decimals: 18,
    address: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    active: true,
    maxBorrow: 50,
    lp: false,
  },
  {
    name: 'WKAVAv1',
    image: '/wkava.png',
    symbol: 'K',
    decimals: 18,
    address: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    active: true,
    deprecated: true,
    maxBorrow: 50,
    lp: false,
  },
  {
    name: 'WETH',
    image: '/weth.png',
    symbol: 'Ξ',
    decimals: 18,
    address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    active: true,
    deprecated: true,
    maxBorrow: 65,
    displayDecimals: 4,
    lp: false,
  },

  {
    name: 'WBTC',
    image: '/wbtc.png',
    symbol: '₿',
    decimals: 8,
    address: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    active: true,
    deprecated: true,
    displayDecimals: 6,
    lp: false,
  },
  {
    name: 'MARE',
    image: '/mare.png',
    symbol: 'MARE',
    decimals: 18,
    address: '0xd86C8d4279CCaFbec840c782BcC50D201f277419',
    active: true,
    lp: false,
  },
  {
    name: 'VARA',
    image: '/vara.png',
    symbol: 'VARA',
    decimals: 18,
    address: '0xE1da44C0dA55B075aE8E2e4b6986AdC76Ac77d73',
    active: true,
    lp: false,
  },
  /*
{
name: "KONK",
image: "/konk.png",
symbol: "KONK",
decimals: 9,
address: "0x13db70Ad2f2b7064EbD5B0CAA13Af445a77360f7",
type: TokenType.ERC20,
active: true,
},
*/
  {
    name: 'DEXI',
    image: '/dexi.png',
    symbol: 'DEXI',
    decimals: 9,
    address: '0xD22a58f79e9481D1a88e00c343885A588b34b68B',
    active: true,
    lp: false,
  },
  {
    name: 'TORE',
    image: '/tore.png',
    symbol: 'TORE',
    decimals: 18,
    address: '0x8549724fcC84ee9ee6c7A676F1Ba2Cc2f43AAF5B',
    active: true,
    deprecated: true,
    lp: false,
  },
  {
    name: 'PINKAV',
    image: '/pinkav.png',
    symbol: 'PINKAV',
    decimals: 18,
    address: '0xE5274E38E91b615D8822e8512a29A16FF1B9C4Af',
    active: true,
    lp: false,
  },
  {
    name: 'BEAR/WBTC',
    image: '/bearLP.png',
    symbol: '$',
    decimals: 18,
    address: '0xeA848151ACB1508988e56Ee7689F004df2B15ced',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'TIGER/multiUSDC',
    image: '/tigerLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x7f8ed7d31795dc6f5fc5f6685b11419674361501',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'LION/multiUSDC',
    image: '/lionLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x09d6561b3795ae237e42f7adf3dc83742e10a2e8',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'MARE/multiUSDC',
    image: '/mareusdcLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x0e1bc1939d977c676cd38cff4b7e411c32b6d3ce',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'VARA/multiUSDC',
    image: '/varausdcLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x9bf1e3ee61cbe5c61e520c8beff45ed4d8212a9a',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'VARA/WKAVA',
    image: '/varawkavaLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x7d8100072ba0e4da8dc6bd258859a5dc1a452e05',
    active: true,
    hidden: true,
    lp: true,
  },
  /*
{
name: "WBTC/WKAVA",
image: "/wbtcwkavaLP.png",
symbol: "$",
decimals: 18,
address: "0x304b111e0c9ccc8137d98807d15d56aad84bce77",
type: TokenType.ERC20,
active: true,
hidden: true,
    lp:true,

},
*/
  /*
{
name: "KONK/WKAVA",
image: "/konkwkavaLP.png",
symbol: "$",
decimals: 18,
address: "0xe54c9655fa97389d2ee8dca1a4fc56138e7a4874",
type: TokenType.ERC20,
active: true,
hidden: true,
    lp:true,

},
*/
  {
    name: 'axlUSDC/multiUSDC',
    image: '/axlusdcusdcLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x7a08708E06A118F2B22C9000A990155bdEdC31d1',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'WKAVA/WETH',
    image: '/wkavawethLP.png',
    symbol: '$',
    decimals: 18,
    address: '0xB593E0A2e93864fF5F75689dADE29f5F6DEc64EF',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'LION/DEXI',
    image: '/liondexiLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x7098c06cd97079742278F637D3EFE4Ce39e19A86',
    active: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'TORE/WKAVA',
    image: '/torewkavaLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x1ae83a1b9Ee963213d1e3Ff337F92930582d304f',
    active: true,
    deprecated: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'PINKAV/WKAVA',
    image: '/pinkavwkavaLP.png',
    symbol: '$',
    decimals: 18,
    address: '0xD271cF9E590121C0dc3bEC562178A281E75B617d',
    active: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'TIGER/LION',
    image: '/tigerlionLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x78Ef6D3E3d0da9B2248C11BE11743B4C573ADd25',
    active: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'USDt/LION',
    image: '/usdtlionLP.png',
    symbol: '$',
    decimals: 18,
    address: '0xa3c6a2f6d67354f43A0b5eC9E46467822750968C',
    active: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'USDt/WKAVA',
    image: '/usdtwkavaLP.png',
    symbol: '$',
    decimals: 18,
    address: '0xBe87F2e81aa16238445651fAbf62Ae097498C200',
    active: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'USDt/axlUSDC',
    image: '/usdtaxlusdcLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x4A18F16b6A4F695639B0d1390263Def2e91fc60f',
    active: true,
    hidden: true,
    lp: true,
  },
  {
    name: 'USDt/axlUSDT',
    image: '/usdtaxlusdtLP.png',
    symbol: '$',
    decimals: 18,
    address: '0x6337e3a13CB76d93856E7403B7f98A37CB2bc7dF',
    active: true,
    hidden: true,
    lp: true,
  },
];

import styled from 'styled-components';
import scrubLogo from "assets/tokenImg/scrubLogo.png"

export const NotFoundWrapper = styled.div`
  background: url(${scrubLogo}) no-repeat;

  background-position: top;
  background-size: cover;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: ChakraBold;
`;

export const NotFoundText = styled.h2`
  font-size: 60px;
  font-family: ChakraBold;
  color: #fff;
  @media screen and (max-width: 550px) {
    font-size: 45px;
  }
`;

export const NotFoundLink = styled.a`
  text-decoration: underline;
`;

import { NotFoundLink, NotFoundText, NotFoundWrapper } from 'app.style';
import ChainIdProvider from 'contexts/ChainIdProvider';
import useChainId from 'hooks/useChainId';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
//scss files
import ReactGA from 'react-ga';
import 'swiper/swiper.min.css';

import { UseWalletProvider } from 'use-wallet';
import config from './config';
import BanksProvider from './contexts/Banks';
import ModalsProvider from './contexts/Modals';
import { RefreshContextProvider } from './contexts/RefreshContext';
import TombFinanceProvider from './contexts/TombFinanceProvider';
import Footer from './layout/footer';
import Header from './layout/header';
import store from './state';
import Updaters from './state/Updaters';

const HomePage = lazy(() => import('./views/Home/HomePage'));
const Cemetery = lazy(() => import('./views/Cemetery'));
const Migrator = lazy(() => import('./views/Cemetery/Migrator'));
const Masonry = lazy(() => import('./views/Masonry'));
const Masonry2 = lazy(() => import('./views/Masonry2'));
const Pit = lazy(() => import('./views/Pit'));

const Sale = lazy(() => import('./views/Sale'));
const CommercePage = lazy(() => import('./views/Commerce'));
const NFTPage = lazy(() => import('./views/NFT'));
const SingleStakePage = lazy(() => import('./views/TigerStake'));

const NFTBid = lazy(() => import('views/NFT/NFTGen0Offer'));

const LionCavePage = lazy(() => import('./views/LionCave'));
const BearCavePage = lazy(() => import('./views/BearCave'));
const LionBombPage = lazy(() => import('./views/LionBomb'));

const NoMatch = () => (
  <NotFoundWrapper>
    <NotFoundText>
      Sorry, this URL was not found.
      <br />
      <NotFoundLink href="/">Go back home.</NotFoundLink>
    </NotFoundText>
  </NotFoundWrapper>
);

const loader = (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div className="reverse-spinner" />
  </div>
);

const App: React.FC = () => {
  // Clear localStorage for mobile users
  if (typeof localStorage.version_app === 'undefined' || localStorage.version_app !== '1.1') {
    //localStorage.clear();
    localStorage.setItem('connectorId', '');
    localStorage.setItem('version_app', '1.1');
  }
  ReactGA.initialize('G-X7RLC4PELV');

  ReactGA.pageview(window.location.pathname);

  return (
    <ChainIdProvider>
      <Providers>
        <Router>
          <Suspense fallback={loader}>
            <Header />
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/migration">
                <Migrator />
              </Route>
              <Route path="/earn">
                <Cemetery />
              </Route>
              <Route path="/compound">
                <Cemetery />
              </Route>
              <Route path="/externalcompound">
                <Cemetery />
              </Route>
              <Route path="/scrub">
                <Masonry2 />
              </Route>
              <Route path="/scrub-legacy">
                <Masonry />
              </Route>
              <Route path="/bonds">
                <Pit />
              </Route>
              <Route path="/dexisale">
                <Sale />
              </Route>
              <Route path="/oldgiftcards">
                <CommercePage />
              </Route>
              <Route path="/nftcollection">
                <NFTPage />
              </Route>
              <Route path="/nftbid">
                <NFTBid />
              </Route>
              <Route path="/singlestake">
                <SingleStakePage />
              </Route>
              <Route path="/lioncave">
                <LionCavePage />
              </Route>
              <Route path="/bearcave">
                <BearCavePage />
              </Route>
              <Route path="/lionbomb">
                <LionBombPage />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
            <Footer />
          </Suspense>
        </Router>
      </Providers>
    </ChainIdProvider>
  );
};

const Providers: React.FC = ({ children }) => {
  const { chainId } = useChainId();

  const [connectors, setConnectors] = useState<any>();
  useEffect(() => {
    if (chainId === 2222) {
      setConnectors({
        walletconnect: {
          rpc: { [chainId]: config[chainId]?.networkUrls[chainId] },
          rpcUrl: config[chainId]?.networkUrls[chainId],
        },
        walletlink: {
          url: config[chainId]?.networkUrls[chainId],
          appName: 'Scrub Money',
          appLogoUrl: 'https://scrub.money/static/media/scrub-logo.14a2418c.svg',
        },
      });
    } else {
      const defaultChain = 2222;
      setConnectors({
        walletconnect: {
          rpc: { [defaultChain]: config[defaultChain]?.networkUrls[defaultChain] },
          rpcUrl: config[defaultChain]?.networkUrls[defaultChain],
        },
        walletlink: {
          url: config[defaultChain]?.networkUrls[defaultChain],
          appName: 'Scrub Money',
          appLogoUrl: 'https://scrub.money/static/media/scrub-logo.14a2418c.svg',
        },
      });
    }
  }, [chainId]);
  return (
    <UseWalletProvider autoConnect connectors={connectors} pollBalanceInterval={15000} pollBlockNumberInterval={15000}>
      <Provider store={store}>
        <Updaters />
        <RefreshContextProvider>
          <TombFinanceProvider>
            <ModalsProvider>
              <BanksProvider>
                <>{children}</>
              </BanksProvider>
            </ModalsProvider>
          </TombFinanceProvider>
        </RefreshContextProvider>
      </Provider>
    </UseWalletProvider>
  );
};

export default App;

import detectEthereumProvider from '@metamask/detect-provider';
import React, { createContext, useState } from 'react';

export interface ChainIdContext {
  chainId?: number;
  setChainId?: (chainId: number) => void;
}

interface ChainProps {
  children?: React.ReactNode;
}

export const Context = createContext<ChainIdContext>({});

export const ChainIdProvider: React.FC<ChainProps> = ({ children }) => {
  const [chainId, setChainId] = useState(null as number);
  function handleEthereum() {
    const defaultNetwork = localStorage.getItem('scrubNetwork');
    console.log('Check for metaask!');
    const { ethereum } = window as any;
    //alert("Storage " + defaultNetwork + " - ChainId" + chainId + " - Ethereum" + ethereum + (ethereum ? " - Eth NEt Ver " + ethereum.networkVersion : ""));

    if (
      (!ethereum || ethereum.networkVersion == null || !ethereum.networkVersion) &&
      chainId != (defaultNetwork != null ? Number(defaultNetwork) : 2222)
    ) {
      //alert("Setting network from storage to "+(defaultNetwork != null ? Number(defaultNetwork) : 25));

      setChainId(defaultNetwork != null ? Number(defaultNetwork) : 2222);
      //alert("Set done from network to "+chainId);

      console.log(defaultNetwork != null ? Number(defaultNetwork) : 2222, 'metamask not injected, use localstorage one');
    }
    if (!chainId && ethereum && ethereum.networkVersion != null) {
      setChainId(2222);

      console.log(ethereum.networkVersion, 'internal');
    }
    //alert("Storage " + defaultNetwork + " - ChainId" + chainId + " - Ethereum" + ethereum + (ethereum ? " - Eth NEt Ver " + ethereum.networkVersion : ""));
  }

  const getProvider = async () => {
    const defaultNetwork = localStorage.getItem('scrubNetwork');
    await detectEthereumProvider({ silent: false, timeout: 15000 });
    console.log(window.ethereum.networkVersion);
    setChainId(
      window.ethereum && window.ethereum.networkVersion
        ? window.ethereum.networkVersion
        : defaultNetwork != null
        ? Number(defaultNetwork)
        : 2222,
    );
  };

  // If the event is not dispatched by the end of the timeout,
  // the user probably doesn't have MetaMask installed.
  handleEthereum();
  getProvider();
  return <Context.Provider value={{ chainId, setChainId }}>{children}</Context.Provider>;
};

import React from 'react';
import { BsDiscord } from 'react-icons/bs';
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import cronosfclogo from 'assets/img/cronosfclogo.jpeg';
import bybitLogo from 'assets/img/bybitlogo.png';

import {
  BackImg,
  BottomOut,
  Land,
  LandOut,
  Left,
  Partner,
  PartnerLogo,
  PartnershipLogos,
  Partnerships,
  PartnershipsHeading,
  SocialIcons,
  Tab,
  Tabs,
} from './footer.style';

export default function FooterContainer() {
  return (
    <Land>
      <BackImg src="/img/footer_effect.png" alt="" />
      <Tabs>
        <Link to="/">
          <Tab>HOME</Tab>
        </Link>

        <a href="https://invest.scrub.money">
          <Tab>INVEST</Tab>
        </a>

        <Link to="/nftcollection">
          <Tab>NFT</Tab>
        </Link>
        <Link to="/singlestake">
          <Tab>TIGER STAKE</Tab>
        </Link>

        <a href="https://commerce.scrub.money">
          <Tab>GIFTCARDS</Tab>
        </a>

        {/*
        <Link to="/lionbomb">
          <Tab>LIONBOMB</Tab>
        </Link>
        */}
      </Tabs>

      <Partnerships>
        <PartnershipsHeading>Partnerships</PartnershipsHeading>
        <PartnershipLogos>
          <Partner href="https://partner.bybit.com/b/scrubmoney" target="_blank" rel="noreferrer noopener">
            <PartnerLogo src={bybitLogo} alt="bybit logo" />
          </Partner>
          
          <Partner href="https://equilibrefinance.com/" target="_blank" rel="noreferrer noopener">
            <PartnerLogo src="https://equilibrefinance.com/images/Logo.png" alt="Equilibre logo" />
          </Partner>
          
          {/*}
          <Partner href="https://cronos.darkcrystl.com//" target="_blank" rel="noreferrer noopener">
            <PartnerLogo src="https://cronos.darkcrystl.com/static/media/logo1.236fcfdb.png" alt="darkcrystal logo" />
          </Partner>
          
          <Partner href="https://minnaoverse.com/" target="_blank" rel="noreferrer noopener">
            <PartnerLogo src="https://minnaoverse.com/_nuxt/img/logo1.367b821.png" alt="minnao logo" />
          </Partner>
          */}
          <Partner href="https://www.occhialiretro.com/" target={'_blank'} rel={'noreferrer noopener'}>
            <PartnerLogo
              src="https://www.myretro.it/img/cms/occhiali-retro-logo-1581647057.jpg"
              alt="occhiali retro logo"
            />
          </Partner>
          <Partner href="https://www.dexioprotocol.com/" target={'_blank'} rel={'noreferrer noopener'}>
            <PartnerLogo
              src="https://assets-global.website-files.com/61efb60c26f04ca47fa56a66/6203ce2687a967d20a66b890_Dexioprotocol%20Verbiage%202-p-800.png"
              alt="occhiali retro logo"
            />
          </Partner>
        </PartnershipLogos>
      </Partnerships>

      <BottomOut>
        <LandOut>
          <Left>Copyright@Scrub Money 2022</Left>
          <SocialIcons>
            <a href="https://twitter.com/ScrubMoneyDeFi" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="socialIcon" />
            </a>
            <a href="https://t.me/ScrubMoney" target="_blank" rel="noopener noreferrer">
              <FaTelegramPlane className="socialIcon" />
            </a>
            <a href="https://discord.gg/dPVjyrUBey" target="_blank" rel="noopener noreferrer">
              <BsDiscord className="socialIcon" />
            </a>
          </SocialIcons>
        </LandOut>
      </BottomOut>
    </Land>
  );
}

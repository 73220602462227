import useChainId from 'hooks/useChainId';
import React, { useCallback, useEffect, useState } from 'react';

import config, { bankDefinitions } from '../../config';
import useTombFinance from '../../hooks/useTombFinance';
import { Bank } from '../../tomb-finance';
import Context from './context';

const Banks: React.FC = ({ children }) => {
  const [banks, setBanks] = useState<Bank[]>([]);
  const tombFinance = useTombFinance();
  const isUnlocked = tombFinance?.isUnlocked;
  const {chainId} = useChainId()

  const fetchPools = useCallback(async () => {
    const banks: Bank[] = [];
    for (const bankInfo of Object.values(bankDefinitions[chainId])) {
      if (bankInfo.finished) {
        if (!tombFinance.isUnlocked) continue;

        // only show pools staked by user
        const balance = await tombFinance.stakedBalanceOnBank(
          bankInfo.contract,
          bankInfo.poolId,
          tombFinance.myAccount,
        );
        if (balance.lte(0)) {
          continue;
        }
      }
      banks.push({
        ...bankInfo,
        address: config[chainId]?.deployments[bankInfo.contract].address,
        depositToken: tombFinance.externalTokens[bankInfo.depositTokenName],
        earnToken:
          bankInfo.earnTokenName === 'TOMB'
            ? tombFinance.TOMB
            : bankInfo.earnTokenName.includes('LP')
            ? tombFinance.externalTokens[bankInfo.earnTokenName]
            : tombFinance.TSHARE,
        tokenSymbol: bankInfo.tokenSymbol,
        earnedTokenSymbol: bankInfo.earnedTokenSymbol,
      });
    }
    banks.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setBanks(banks);
  }, [tombFinance, setBanks]);

  useEffect(() => {
    if (tombFinance) {
      fetchPools().catch((err) => console.error(`Failed to fetch pools: ${err.stack}`));
    }
  }, [isUnlocked, tombFinance, fetchPools]);

  return <Context.Provider value={{ banks }}>{children}</Context.Provider>;
};

export default Banks;

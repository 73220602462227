export const TOMB_TICKER = 'TOMB';
export const BEAR_TICKER = 'BEAR';
export const MMF_TICKER = 'MMF';

export const TSHARE_TICKER = 'TSHARE';
export const TBOND_TICKER = 'TBOND';
export const FTM_TICKER = 'CRO';
export const MSHARE_TICKER = 'MSHARE';
export const WFTM_TICKER = 'WCRO';
export const SPOOKY_ROUTER_ADDR = '0x0db22B8424EbAc5d073c09ff8975960BfFdbd7bF';
export const ANNEX_ROUTER_ADDR = '0x77bEFDE82ebA4BDC4D3E4a853BF3EA4FFB49Dd58';
export const ELK_ROUTER_ADDR = '0xa7544c409d772944017bb95b99484b6e0d7b6388';
export const ZAPPER_ROUTER_ADDR = '0xA73456EEbc8cc7B440BFeC2e6615C1234De2c3Ab';
export const TAX_OFFICE_ADDR = '0xcaf6C0FB8Bcb737C2D5D7e5Db615147a26091De1';
export const MIGRATOR_ADDR = '0x95d3C648239B9C54766981A78a0ddd06e61b1B19';
export const KAVA_MIGRATOR_ADDR = '0x782146d11d09e0FCeaf9a2Eb9b5B0a25185d0AaC';

export const MMF_ROUTER_ADDR = '0x145677FC4d9b8F19B5D56d1820c48e0443049a30';

export const TIGER_TICKER = 'TIGER';
export const LION_TICKER = 'LION';
export const SVN_TICKER = 'SVN';
export const USDC_TICKER = 'USDC';
export const WBTC_TICKER = 'WBTC';
export const DARKCRYSTL_TICKER = 'DARKCRYSTL';
export const MINE_TICKER = 'MINE';
export const MUSD_TICKER = 'MUSD';
export const WCRO_TICKER = 'WCRO';

// @ts-nocheck
import { Contract, EventFilter, ethers, BigNumber } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import moment from 'moment';
import { getPairFromAddress } from 'utils/utils';

import { TransactionResponse } from '@ethersproject/providers';
import { Multicall, ContractCallContext } from 'ethereum-multicall';
import { bankDefinitions } from '../config';
import ERC20ABI from '../tomb-finance/ERC20.abi.json';
import {
  BEAR_TICKER,
  FTM_TICKER,
  LION_TICKER,
  MMF_TICKER,
  ANNEX_ROUTEmeR_ADDR,
  MMF_ROUTER_ADDR,
  MSHARE_TICKER,
  SPOOKY_ROUTER_ADDR,
  TIGER_TICKER,
  TOMB_TICKER,
  TSHARE_TICKER,
  WBTC_TICKER,
  DARKCRYSTL_TICKER,
  MINE_TICKER,
  MUSD_TICKER,
  WCRO_TICKER,
  ELK_ROUTER_ADDR,
} from '../utils/constants';
import { getDisplayBalance, getFullDisplayBalance } from '../utils/formatBalance';
import { getDefaultProvider } from '../utils/provider';
import ERC20 from './ERC20';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import UniswapV2Router from './abis/UniswapV2Router02.json';
import { Configuration, WHITELISTED_TOKENS } from './config';
import { decimalToBalance } from './ether-utils';
import {
  AllocationTime,
  Bank,
  ContractName,
  ExpandTokenStat,
  LPStat,
  PoolStats,
  TShareSwapperStat,
  TokenStat,
  Product,
  Order,
  Info,
  MulticallInfo,
  MigrationUserInfo,
} from './types';
import { Deployments } from './deployments';
import { CallContext } from 'ethereum-multicall/dist/esm/models';
import useChainId from 'hooks/useChainId';

/**
 * An API module of Tomb Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */
const CoinGecko = require('coingecko-api');

export class TombFinance {
  myAccount: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  deployments: Deployments;
  externalTokens: { [name: string]: ERC20 };
  masonryVersionOfUser?: string;

  TOMB: ERC20;
  TSHARE: ERC20;
  TBOND: ERC20;
  FTM: ERC20;
  SVN: ERC20;
  USDC: ERC20;
  WBTC: WRC20;
  ONE: BigNumber;
  allTokenPrices: { [name: string]: number };

  burntAddress: string;

  migrationAddress: string;

  constructor(cfg: Configuration) {
    const { deployments, externalTokens } = cfg;
    this.deployments = deployments;
    const provider = getDefaultProvider(cfg.chainId);

    // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
    }
    this.TOMB = new ERC20(deployments.tomb.address, provider, 'LION');
    this.TSHARE = new ERC20(deployments.tShare.address, provider, 'TIGER');
    this.TBOND = new ERC20(deployments.tBond.address, provider, 'TBOND');
    this.FTM = this.externalTokens['WFTM'];
    this.SVN = this.externalTokens['SVN'];
    this.USDC = this.externalTokens['USDC'];
    this.WBTC = this.externalTokens['WBTC'];

    this.burntAddress = '0x000000000000000000000000000000000000dead';
    this.migrationAddress =
      cfg.chainId == 25 ? '0x782146d11d09e0FCeaf9a2Eb9b5B0a25185d0AaC' : '0x5fc331f000094c3A9B53629cF9c47c2f93ab9722';
    this.ONE = BigNumber.from(1)
      .mul(10 ** 5)
      .mul(10 ** 5)
      .mul(10 ** 8);

    // Uniswap V2 Pair
    const { LionUsdcLPTigerRewardPool } = bankDefinitions[cfg.chainId];

    this.config = cfg;
    this.provider = provider;
  }

  async sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(provider, this.config.chainId);
    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = [this.TOMB, this.TSHARE, this.TBOND, ...Object.values(this.externalTokens)];
    for (const token of tokens) {
      token.connect(this.signer);
    }
    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
    this.masonryVersionOfUser = 'latest';
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }
  async getERC20InstanceFromContract(address: string): Promise<ERC20> {
    const contract = new Contract(address, ERC20ABI, this.provider);
    const erc20 = new ERC20(address, this.provider, await contract.symbol(), await contract.decimals());

    return erc20;
  }
  //===================================================================
  //===================== GET ASSET STATS =============================
  //===================FROM SPOOKY TO DISPLAY =========================
  //=========================IN HOME PAGE==============================
  //===================================================================

  async getTombStat(): Promise<ExpandTokenStat> {
    const supply = await this.TOMB.totalSupply();
    const burnt = await this.TOMB.balanceOf(this.burntAddress);
    const migration = await this.TOMB.balanceOf(this.migrationAddress);
    const nftgen1 = await this.TOMB.balanceOf(this.deployments['ScrubMoneyNFTGen1'].address);
    const lockedpool = await this.TOMB.balanceOf(this.deployments['LionTigerRewardPoolLocked'].address);
    const lockedpool2 = await this.TOMB.balanceOf(this.deployments['LionTigerRewardPoolLockedNew'].address);
    const cave = await this.TOMB.balanceOf(this.deployments['lioncave'].address);
    const commerce = await this.TOMB.balanceOf('0x52b18024e084150e001a34be9c7a41706517d79f');
    const backingFund = await this.TOMB.balanceOf('0x932A3FE07002D0B98A2Dec0B70beE186b0b37c80');
    const deployer = await this.TOMB.balanceOf('0xD47D2f1543CdaE1284f20705a32B1362422cB652');
    const eventCenter = await this.TOMB.balanceOf('0xa07deE8FF35fE2e2961a7e1006EAdA98E24aE82E');
    const tombCirculatingSupply = supply
      .sub(burnt)
      .sub(migration)
      .sub(nftgen1)
      .sub(lockedpool)
      .sub(lockedpool2)
      .sub(commerce)
      .sub(cave)
      .sub(deployer)
      .sub(eventCenter);
    const priceInFTM = await this.getTokenPriceFromPancakeswap(this.TOMB);
    const priceOfOneFTM = await this.getWFTMPriceFromPancakeswap();
    console.log('LION', priceInFTM, priceOfOneFTM);
    const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);
    const lastEpochPrice = await this.contracts.Treasury.previousEpochLionPrice();
    return {
      tokenInFtm: priceInFTM,
      priceInDollars: priceInFTM,
      totalSupply: getDisplayBalance(supply, this.TOMB.decimal, 0),
      circulatingSupply: getDisplayBalance(tombCirculatingSupply, this.TOMB.decimal, 0),
      lastEpochPrice: getDisplayBalance(lastEpochPrice),
    };
  }

  async getTokenStat(token: ERC20): Promise<TokenStat> {
    const supply = await token.totalSupply();
    const burnt = await token.balanceOf(this.burntAddress);
    const migration = await token.balanceOf(this.migrationAddress);

    const tombCirculatingSupply = supply.sub(burnt).sub(migration);
    const baseToken = await this.getBaseTokenForToken(token);
    const priceInFTM = baseToken === token ? 1 : await this.getTokenPriceFromPancakeswap(token);
    console.log(`${token.symbol} price in base ${priceInFTM}`);
    const priceOfOneFTM = await this.getTokenPriceInUSDCFromPancakeswap(baseToken);
    console.log(`base price in USDC ${priceOfOneFTM}`);
    const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(4);
    console.log(`${token.symbol} price in USDC ${priceOfTombInDollars}`);

    return {
      tokenInFtm: priceInFTM,
      priceInDollars: priceOfTombInDollars,
      totalSupply: getDisplayBalance(supply, this.TOMB.decimal, 0),
      circulatingSupply: getDisplayBalance(tombCirculatingSupply, this.TOMB.decimal, 0),
    };
  }

  async getPeg(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WBTC } = this.config.externalTokens;

    const token = new Token(this.config.chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);

    const router = new ethers.Contract(ANNEX_ROUTER_ADDR, UniswapV2Router.abi, this.provider);
    const response = await router.getAmountsIn(1e18, [WBTC[0], token.address]);
    console.log(response);
    return response[1].toString();
  }

  async getBearStat(): Promise<any> {
    const { BEAR, WBTC } = this.externalTokens;
    const supply = await BEAR.totalSupply();
    const burnt = await BEAR.balanceOf(this.burntAddress);
    const migration = await BEAR.balanceOf(this.migrationAddress);

    const tombCirculatingSupply = supply.sub(burnt).sub(migration);
    const priceInFTM = await this.getTokenPriceFromPancakeswap(BEAR);
    const btcPrice = await this.getTokenPriceFromPancakeswap(WBTC);
    console.log('BTC PRICE', btcPrice);
    const priceOfOneFTM = btcPrice;
    const priceOfTombInDollars = (Number(priceInFTM) * Number(priceOfOneFTM)).toFixed(2);
    const lastEpochPrice = await this.contracts.BearTreasury.previousEpochBearPrice();

    return {
      tokenInFtm: priceInFTM,
      priceInDollars: priceOfTombInDollars,
      totalSupply: getDisplayBalance(supply, this.externalTokens.BEAR.decimal, 0),
      circulatingSupply: getDisplayBalance(tombCirculatingSupply, this.externalTokens.BEAR.decimal, 0),
      lastEpochPrice: getDisplayBalance(lastEpochPrice),
    };
  }

  /**
   * Calculates various stats for the requested LP
   * @param name of the LP token to load stats for
   * @returns
   */
  async getLPStat(name: string): Promise<LPStat> {
    const ready = await this.provider.ready;
    if (!ready) return;
    console.log('Checking stat of ', name);
    const lpToken = getPairFromAddress(this.externalTokens[name].address, this.provider);
    console.log('LP Token loaded: ', lpToken.address);
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18, 18, false);
    console.log('Total supply of ', name, ' ', lpTokenSupply, lpTokenSupplyBN);
    const token0 = await this.getERC20InstanceFromContract(await lpToken.token0());
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, token0.decimal, token0.decimal, false);
    console.log(`Token 0 ${token0.symbol} - ${tokenAmount} `);
    const token1 = await this.getERC20InstanceFromContract(await lpToken.token1());
    const token1AmountBN = await token1.balanceOf(lpToken.address);
    const token1Amount = getDisplayBalance(token1AmountBN, token1.decimal, token1.decimal, false);
    console.log(`Token 1 ${token1.symbol} - ${token1Amount} `);
    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const ftmAmountInOneLP = Number(token1Amount) / Number(lpTokenSupply);
    console.log(`1 ${name} - ${tokenAmountInOneLP} / ${ftmAmountInOneLP} `);
    //in case of bear btw we need to use the btc as token for calculation or we get peg...
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token1);
    console.log(
      `1 ${name} - ${lpTokenPrice} $ supply: ${lpTokenSupply} liquidity: ${
        Number(lpTokenSupply) * Number(lpTokenPrice)
      }`,
    );
    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(2).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(2).toString();
    return {
      tokenAmount: tokenAmountInOneLP.toFixed(2).toString(),
      ftmAmount: ftmAmountInOneLP.toFixed(2).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(2).toString(),
      priceInDollars: lpTokenPrice,
    };
  }

  /**
   * Use this method to get price for Tomb
   * @returns TokenStat for TBOND
   * priceInFTM
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async getBondStat(): Promise<TokenStat> {
    const { Treasury } = this.contracts;
    const tombStat = await this.getTombStat();
    const bondTombRatioBN = await Treasury.getBondPremiumRate();
    const modifier = bondTombRatioBN / 1e18 > 1 ? bondTombRatioBN / 1e18 : 1;
    const bondPriceInFTM = (Number(tombStat.tokenInFtm) * modifier).toFixed(2);
    const priceOfTBondInDollars = (Number(tombStat.priceInDollars) * modifier).toFixed(2);
    const supply = await this.TBOND.displayedTotalSupply();
    return {
      tokenInFtm: bondPriceInFTM,
      priceInDollars: priceOfTBondInDollars,
      totalSupply: supply,
      circulatingSupply: supply,
    };
  }

  /**
   * Use this method to get price for Bear
   * @returns TokenStat for BBEAR
   * priceInFTM
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async getBearBondStat(): Promise<TokenStat> {
    const { BearTreasury } = this.contracts;
    const bearStat = await this.getBearStat();
    const bondBearRatioBN = await BearTreasury.getBondPremiumRate();
    const modifier = bondBearRatioBN / 1e18 > 1 ? bondBearRatioBN / 1e18 : 1;
    const bondBearPriceInFTM = (Number(bearStat.tokenInFtm) * modifier).toFixed(2);
    const priceOfBearBondInDollars = (Number(bearStat.priceInDollars) * modifier).toFixed(2);
    const supply = await this.externalTokens.BEAR.displayedTotalSupply();
    return {
      tokenInFtm: bondBearPriceInFTM,
      priceInDollars: priceOfBearBondInDollars,
      totalSupply: supply,
      circulatingSupply: supply,
    };
  }

  /**
   * @returns TokenStat for TSHARE
   * priceInFTM
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async getShareStat(): Promise<TokenStat> {
    const { LionUsdcLPTigerRewardPool } = this.contracts;

    const supply = await this.TSHARE.totalSupply();

    const priceInFTM = await this.getTokenPriceFromPancakeswap(this.TSHARE);
    const tombRewardPoolSupply = await this.TSHARE.balanceOf(LionUsdcLPTigerRewardPool.address);
    const burnt = await this.TSHARE.balanceOf(this.burntAddress);
    const migration = await this.TSHARE.balanceOf(this.migrationAddress);

    const tShareCirculatingSupply = supply.sub(tombRewardPoolSupply).sub(burnt).sub(migration);
    const priceOfSharesInDollars = priceInFTM;
    console.log('Share price in dollars', priceOfSharesInDollars, this.TSHARE.address, this.allTokenPrices);

    return {
      tokenInFtm: priceInFTM,
      priceInDollars: priceOfSharesInDollars,
      totalSupply: getDisplayBalance(supply, this.TSHARE.decimal, 0),
      circulatingSupply: getDisplayBalance(tShareCirculatingSupply, this.TSHARE.decimal, 0),
    };
  }

  async getTombStatInEstimatedTWAP(): Promise<TokenStat> {
    const { SeigniorageOracle } = this.contracts;
    const chainId = this.config.chainId;
    const decimals = 18;
    const expectedPrice = (await this.getTokenPrice(this.TOMB.address)) / 0.025;
    console.log('Lion twap', expectedPrice);
    const supply = await this.TOMB.totalSupply();
    const tombCirculatingSupply = supply;
    const burnt = await this.TOMB.balanceOf(this.burntAddress);
    const migration = await this.TOMB.balanceOf(this.migrationAddress);

    const circulatingSupply = supply.sub(burnt).sub(migration);

    return {
      tokenInFtm: expectedPrice,
      priceInDollars: expectedPrice,
      totalSupply: getDisplayBalance(supply, this.TOMB.decimal, 0),
      circulatingSupply: getDisplayBalance(circulatingSupply, this.TOMB.decimal, 0),
    };
  }

  async getBearStatInEstimatedTWAP(): Promise<TokenStat> {
    const { SeigniorageBearOracle } = this.contracts;

    const BEAR = this.externalTokens.BEAR;
    console.log('Bear oracle address' + SeigniorageBearOracle.address);
    console.log('Bear  address' + BEAR.address);
    const chainId = this.config.chainId;
    const decimals = 18;
    const expectedPrice = await SeigniorageBearOracle.twap(BEAR.address, ethers.utils.parseEther('1'));
    console.log('Bear twap ' + expectedPrice);
    const supply = await BEAR.totalSupply();
    const burnt = await BEAR.balanceOf(this.burntAddress);
    const migration = await BEAR.balanceOf(this.migrationAddress);
    const tombCirculatingSupply = supply.sub(burnt).sub(migration);
    return {
      tokenInFtm: getDisplayBalance(expectedPrice, decimals),
      priceInDollars: getDisplayBalance(expectedPrice, decimals),
      totalSupply: getDisplayBalance(supply, BEAR.decimal, 0),
      circulatingSupply: getDisplayBalance(tombCirculatingSupply, BEAR.decimal, 0),
    };
  }

  async getLionPriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getLionUpdatedPrice();
  }

  async getBearPriceInLastTWAP(): Promise<BigNumber> {
    const { BearTreasury } = this.contracts;
    return BearTreasury.getBearUpdatedPrice();
  }

  async getBondsPurchasable(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBurnableLionLeft();
  }

  async getBearBondsPurchasable(): Promise<BigNumber> {
    const { BearTreasury } = this.contracts;
    return BearTreasury.getBurnableBearLeft();
  }

  addToMulticall = (multiCallInfo: MulticallInfo, callList: CallContext[]) => {
    let multicall;
    if (this.config.chainId == 25) {
      multicall = new Multicall({
        ethersProvider: this.provider,
        tryAggregate: true,
      });
    } else {
      multicall = new Multicall({
        ethersProvider: this.provider,
        tryAggregate: true,
        multicallCustomContractAddress: this.contracts['Multicall'].address,
      });
    }
    const contractCallContext: ContractCallContext[] = [
      {
        reference: multiCallInfo.contractName,
        contractAddress: multiCallInfo.address,
        abi: multiCallInfo.abi,
        calls: callList,
      },
    ];
    try {
      callList.push({
        reference: multiCallInfo.reference,
        methodName: multiCallInfo.methodName,
        methodParameters: multiCallInfo.parameters,
      });
    } catch {
      console.log('Failed to add call!');
    }
    return multicall, contractCallContext;
  };

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns
   */
  async getPoolAPRsMulticall(bank: Bank): Promise<PoolStats> {
    if (this.myAccount === undefined) return;
    const depositToken = bank.depositToken;
    const farmContract = this.contracts[bank.farmContract];
    const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
    const stakeInPool = await depositToken.balanceOf(farmContract.address);
    const TVL =
      Number(depositTokenPrice) *
      Number(stakeInPool.gt(0) ? getDisplayBalance(stakeInPool, depositToken.decimal, depositToken.decimal) : 0);

    const earnToken =
      bank.farmEarnTokenName === 'LION'
        ? this.TOMB
        : bank.farmEarnTokenName === 'TIGER'
        ? this.TSHARE
        : this.externalTokens[bank.farmEarnTokenName];
    const earnTokenPriceInDollars = await this.getTokenPriceFromPancakeswap(earnToken);
    const tokenPerSecond = await this.getTokenPerSecond(
      bank.poolId,
      bank.contract,
      farmContract,
      bank.depositTokenName,
    );
    const tokenPerHour = tokenPerSecond.mul(60).mul(60);
    //console.log(bank.depositTokenName, " price is ", depositTokenPrice)
    console.log(Number(stakeInPool), ' staked in pool');
    console.log(TVL);
    //console.log(tokenPerHour)
    const totalRewardPricePerYear =
      Number(earnTokenPriceInDollars) *
      Number(getDisplayBalance(tokenPerHour.mul(24).mul(365), depositToken.decimal, depositToken.decimal));
    const totalRewardPricePerDay =
      Number(earnTokenPriceInDollars) *
      Number(getDisplayBalance(tokenPerHour.mul(24), depositToken.decimal, depositToken.decimal));
    const totalStakingTokenInPool =
      Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal, depositToken.decimal));
    const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
    const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;
    let strategy = null;
    let pendingToHarvest = BigNumber.from(0);
    let pendingToHarvestDollars = BigNumber.from(0);
    let userPendingToHarvestDollars = BigNumber.from(0);
    let lastEarned = 0;
    let lastEarnedDate = new Date();
    let buybackRate = BigNumber.from(0);
    let vaultTVL = TVL;
    let userStakedBalance = await this.stakedBalanceOnBank(bank.contract, bank.poolId);
    let additionalRewards = BigNumber.from(0);
    let poolStakedTokens = BigNumber.from(0);
    if (bank.contract.includes('Vault')) {
      pendingHarvest = await strategy.pendingHarvest();
      pendingHarvestDollarValue = await strategy.pendingHarvestDollarValue();
      lastEarnTime = await strategy.lastEarnTime();
      lastEarnedDate = new Date(lastEarned * 1000);
      buyBackRate = await strategy.buyBackRate();
      poolStakedTokens = await strategy.inFarmBalance();
      userPendingToHarvestDollars = poolStakedTokens.gt(0)
        ? pendingToHarvestDollars.mul(userStakedBalance).div(poolStakedTokens)
        : BigNumber.from(0);
      vaultTVL = (poolStakedTokens.gt(0) ? getDisplayBalance(poolStakedTokens, 18, 18) : 0) * depositTokenPrice;
      this.getPendingRewardOnVault(bank.contract, bank.poolId).then((pendingReward) => {
        additionalRewards = pendingReward.gt(0) ? getDisplayBalance(pendingReward) : 0;
      });
    } else {
    }
    console.log('Staked in pool: ', getDisplayBalance(stakeInPool, 18, 18));
    console.log('PRICE OF ', depositToken.symbol, ' :', depositTokenPrice);
    console.log('TVL: ', TVL);
    console.log('TVL: ', vaultTVL);
    return {
      dailyAPR: dailyAPR.toFixed(2).toString(),
      yearlyAPR: yearlyAPR.toFixed(2).toString(),
      TVL: TVL.toFixed(2).toString(),
      apy: (((1 + yearlyAPR / 100 / 365) ** 365 - 1) * 100).toFixed(0),
      pendingToHarvest: getDisplayBalance(pendingToHarvest),
      pendingToHarvestInDollars: pendingToHarvestDollars.gt(0) ? getDisplayBalance(pendingToHarvestDollars, 6) : 0,
      userPendingToHarvestInDollars: userPendingToHarvestDollars.gt(0)
        ? getDisplayBalance(userPendingToHarvestDollars, 6)
        : 0,
      lastEarned: lastEarnedDate.toLocaleTimeString(),
      buybackRate: Number(buybackRate.div(100)),
      vaultTVL: vaultTVL,
      additionalRewards: additionalRewards,
      additionalRewardsInDollars: (additionalRewards * earnTokenPriceInDollars).toFixed(2),
    };
  }

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns
   */
  async getPoolAPRs(bank: Bank): Promise<PoolStats> {
    if (this.config.chainId == 25) return this.getPoolAPRsTracker(bank);
    if (this.myAccount === undefined) return;
    const depositToken = bank.depositToken;
    const farmContract = this.contracts[bank.farmContract];
    const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
    const stakeInPool = await depositToken.balanceOf(farmContract.address);
    const TVL =
      Number(depositTokenPrice) *
      Number(stakeInPool.gt(0) ? getDisplayBalance(stakeInPool, depositToken.decimal, depositToken.decimal) : 0);

    const earnToken =
      bank.farmEarnTokenName === 'LION'
        ? this.TOMB
        : bank.farmEarnTokenName === 'TIGER'
        ? this.TSHARE
        : this.externalTokens[bank.farmEarnTokenName];
    const earnTokenPriceInDollars = await this.getTokenPriceFromPancakeswap(earnToken);
    const tokenPerSecond = await this.getTokenPerSecond(
      bank.poolId,
      bank.contract,
      farmContract,
      bank.depositTokenName,
    );
    const tokenPerHour = tokenPerSecond.mul(60).mul(60);
    //console.log(bank.depositTokenName, " price is ", depositTokenPrice)
    //console.log(Number(stakeInPool), ' staked in pool');
    //console.log(TVL);
    //console.log(tokenPerHour)
    const totalRewardPricePerYear =
      Number(earnTokenPriceInDollars) *
      Number(getDisplayBalance(tokenPerHour.mul(24).mul(365), depositToken.decimal, depositToken.decimal));
    const totalRewardPricePerDay =
      Number(earnTokenPriceInDollars) *
      Number(getDisplayBalance(tokenPerHour.mul(24), depositToken.decimal, depositToken.decimal));
    const totalStakingTokenInPool =
      Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal, depositToken.decimal));
    const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
    const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;
    let strategy = null;
    let pendingToHarvest = BigNumber.from(0);
    let pendingToHarvestDollars = BigNumber.from(0);
    let userPendingToHarvestDollars = BigNumber.from(0);
    let lastEarned = 0;
    let lastEarnedDate = new Date();
    let buybackRate = BigNumber.from(0);
    let vaultTVL = TVL;
    let userStakedBalance = await this.stakedBalanceOnBank(bank.contract, bank.poolId);
    let additionalRewards = BigNumber.from(0);
    let poolStakedTokens = BigNumber.from(0);
    if (bank.contract.includes('Vault')) {
      strategy = this.contracts[bank.contract + 'Strategy'];
      pendingToHarvest = await strategy.pendingHarvest();
      pendingToHarvestDollars = await strategy.pendingHarvestDollarValue();
      lastEarned = await strategy.lastEarnTime();
      lastEarnedDate = new Date(lastEarned * 1000);
      buybackRate = await strategy.buyBackRate();
      poolStakedTokens = await strategy.inFarmBalance();

      vaultTVL = (poolStakedTokens.gt(0) ? getDisplayBalance(poolStakedTokens, 18, 18) : 0) * depositTokenPrice;
    }
    console.log('Staked in pool: ', getDisplayBalance(stakeInPool, 18, 18));
    console.log('PRICE OF ', depositToken.symbol, ' :', depositTokenPrice);
    console.log('TVL: ', TVL);
    console.log('TVL: ', vaultTVL);
    return {
      dailyAPR: dailyAPR.toFixed(2).toString(),
      yearlyAPR: yearlyAPR.toFixed(2).toString(),
      TVL: TVL.toFixed(2).toString(),
      apy: (((1 + yearlyAPR / 100 / 365) ** 365 - 1) * 100).toFixed(0),
      pendingToHarvest: getDisplayBalance(pendingToHarvest),
      pendingToHarvestInDollars: pendingToHarvestDollars.gt(0) ? getDisplayBalance(pendingToHarvestDollars, 6) : 0,
      userPendingToHarvestInDollars: userPendingToHarvestDollars.gt(0)
        ? getDisplayBalance(userPendingToHarvestDollars, 6)
        : 0,
      lastEarned: lastEarnedDate.toLocaleTimeString(),
      buybackRate: Number(buybackRate.div(100)),
      vaultTVL: vaultTVL,
      additionalRewards: additionalRewards,
      additionalRewardsInDollars: (additionalRewards * earnTokenPriceInDollars).toFixed(2),
    };
  }

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns
   */
  async getPoolAPRsTracker(bank: Bank): Promise<PoolStats> {
    if (this.myAccount === undefined) return;
    const depositToken = bank.depositToken;
    let stats: {
      priceToken0: BigNumber;
      priceToken1: BigNumber;
      tvl: BigNumber;
      lpPrice: BigNumber;
      vaultTvl: BigNumber;
      dailyApr: BigNumber;
    };
    stats = await this.getStatsForBank(bank);

    const depositTokenPrice = stats.lpPrice;
    const TVL = getDisplayBalance(stats.tvl, 6, 2);
    const dailyAPR = Number(stats.dailyApr) / 100;
    let strategy = null;
    let pendingToHarvest = BigNumber.from(0);
    let pendingToHarvestDollars = BigNumber.from(0);
    let userPendingToHarvestDollars = BigNumber.from(0);
    let lastEarned = 0;
    let lastEarnedDate = new Date();
    let buybackRate = BigNumber.from(0);
    let vaultTVL = getDisplayBalance(stats.vaultTvl, 6, 2);
    let userStakedBalance = await this.stakedBalanceOnBank(bank.contract, bank.poolId);
    let additionalRewards = BigNumber.from(0);
    if (bank.contract.includes('Vault')) {
      strategy = this.contracts[bank.contract + 'Strategy'];
      pendingToHarvest = await strategy.pendingHarvest();
      pendingToHarvestDollars = await strategy.pendingHarvestDollarValue();
      lastEarned = await strategy.lastEarnTime();
      lastEarnedDate = new Date(lastEarned * 1000);
      buybackRate = await strategy.buyBackRate();
      const poolStakedTokens = await strategy.inFarmBalance();
      userPendingToHarvestDollars = poolStakedTokens.gt(0)
        ? pendingToHarvestDollars.mul(userStakedBalance).div(poolStakedTokens)
        : BigNumber.from(0);
      const pendingReward = await this.getPendingRewardOnVault(bank.contract, bank.poolId);
      additionalRewards = pendingReward.gt(0) ? getDisplayBalance(pendingReward) : 0;
    }
    console.log('PRICE OF ', await depositToken.symbol, ' :', depositTokenPrice);
    console.log('TVL: ', TVL);
    console.log('TVL: ', vaultTVL);

    const yearlyAPR = dailyAPR * 365;
    const earnToken =
      bank.farmEarnTokenName === 'LION'
        ? this.TOMB
        : bank.farmEarnTokenName === 'TIGER'
        ? this.TSHARE
        : this.externalTokens[bank.farmEarnTokenName];
    const earnTokenPriceInDollars = await this.getTokenPriceFromPancakeswap(earnToken);

    return {
      dailyAPR: dailyAPR.toFixed(2).toString(),
      yearlyAPR: yearlyAPR.toFixed(2).toString(),
      TVL: TVL,
      apy: (((1 + yearlyAPR / 100 / 365) ** 365 - 1) * 100).toFixed(0),
      pendingToHarvest: getDisplayBalance(pendingToHarvest),
      pendingToHarvestInDollars: pendingToHarvestDollars.gt(0) ? getDisplayBalance(pendingToHarvestDollars, 6) : 0,
      userPendingToHarvestInDollars: userPendingToHarvestDollars.gt(0)
        ? getDisplayBalance(userPendingToHarvestDollars, 6)
        : 0,
      lastEarned: lastEarnedDate.toLocaleTimeString(),
      buybackRate: Number(buybackRate.div(100)),
      vaultTVL: vaultTVL,
      additionalRewards: additionalRewards,
      additionalRewardsInDollars: (additionalRewards * earnTokenPriceInDollars).toFixed(2),
    };
  }

  private async getStatsForBank(bank: Bank) {
    const farmContract = this.contracts[bank.farmContract];
    console.log(
      'Checking stats for ',
      this.contracts[bank.contract].address,
      0,
      bank.poolId,
      farmContract.address,
      bank.depositTokenName.includes('MMF') ? MMF_ROUTER_ADDR : ANNEX_ROUTER_ADDR,
    );
    let stats;
    if (bank.contract.includes('Vault')) {
      const { VaultTracker, MineVaultTracker } = this.contracts;
      let vault =
        bank.depositTokenName.includes('DARKCRYSTL') || bank.depositTokenName.includes('MINE')
          ? MineVaultTracker
          : VaultTracker;
      console.log('VAULT', vault.address, farmContract.address);

      const result = await vault.getVaultInfo(
        this.contracts[bank.contract].address,
        '0x0000000000000000000000000000000000000000',
        bank.poolId,
        farmContract.address,
        bank.depositTokenName.includes('MMF') ? MMF_ROUTER_ADDR : ANNEX_ROUTER_ADDR,
      );
      console.log('RESULT IS ', result);
      console.log(
        Number(result[0]),
        Number(result[1]),
        Number(result[2]),
        Number(result[3]),
        Number(result[4]),
        Number(result[5]),
      );
      stats = {
        priceToken0: result[0],
        priceToken1: result[1],
        tvl: result[2],
        lpPrice: result[3],
        vaultTvl: result[4],
        dailyApr: result[5],
      };
    } else if (bank.contract.includes('Locked')) {
      console.log('POOL');
      const { PoolTracker } = this.contracts;
      const result = await PoolTracker.getPoolInfo(bank.poolId, farmContract.address, ANNEX_ROUTER_ADDR);
      console.log(result);
      stats = {
        priceToken0: result[0],
        priceToken1: BigNumber.from(0),
        tvl: result[2],
        lpPrice: BigNumber.from(0),
        vaultTvl: BigNumber.from(0),
        dailyApr: result[5],
      };
    } else {
      console.log('FARM');
      const { FarmTracker } = this.contracts;
      const result = await FarmTracker.getFarmInfo(bank.poolId, farmContract.address, ANNEX_ROUTER_ADDR);
      console.log(result);
      stats = {
        priceToken0: result[0],
        priceToken1: result[1],
        tvl: result[2],
        lpPrice: result[3],
        vaultTvl: result[4],
        dailyApr: result[5],
      };
    }
    return stats;
  }

  /**
   * Method to return the amount of tokens the pool yields per second
   * @param earnTokenName the name of the token that the pool is earning
   * @param contractName the contract of the pool/bank
   * @param poolContract the actual contract of the pool
   * @returns
   */
  async getTokenPerSecond(poolId: string, contractName: string, poolContract: Contract, depositTokenName: string) {
    let _poolId = poolId;
    if (contractName.includes('Vault')) {
      const strategy = this.contracts[contractName + 'Strategy'];
      _poolId = await strategy.pid();
    }
    const rewardPerSecond = poolContract.tSharePerSecond
      ? await poolContract.tSharePerSecond()
      : await poolContract.mSharePerSecond();

    const totalAllocPoint = await poolContract.totalAllocPoint();
    const allocPoint = (await poolContract.poolInfo(_poolId)).allocPoint;
    //console.log("TOTALALLOC: ",totalAllocPoint,"ALLOC TO POOL ",allocPoint);
    return rewardPerSecond.mul(allocPoint).div(totalAllocPoint);
  }

  /**
   * Method to calculate the tokenPrice of the deposited asset in a pool/bank
   * If the deposited token is an LP it will find the price of its pieces
   * @param tokenName
   * @param pool
   * @param token
   * @returns
   */
  async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
    let tokenPrice;
    if (!tokenName.includes('LP')) {
      tokenPrice = await this.getTokenPriceFromPancakeswap(token);
    } else {
      if (tokenName === 'LION-USDC-MMF-LP' || tokenName === 'LION-USDC-MMF-LP') {
        tokenPrice = await this.getLPTokenPrice(token, this.TOMB);
      } else if (tokenName === 'TIGER-USDC-LP' || tokenName === 'TIGER-USDC-MMF-LP') {
        tokenPrice = await this.getLPTokenPrice(token, this.TSHARE);
      } else if (tokenName === 'BEAR-WBTC-LP' || tokenName === 'BEAR-WBTC-MMF-LP') {
        tokenPrice = await this.getLPTokenPrice(token, this.externalTokens.WBTC);
      } else if (tokenName.includes('MMF') && tokenName.includes('SVN') && tokenName.includes('LP')) {
        const { MMF } = this.externalTokens;
        tokenPrice = await this.getLPTokenPrice(token, MMF);
      } else if (tokenName == 'LION') {
        const { LION } = this.externalTokens;
        tokenPrice = await this.getTokenPriceFromPancakeswap(LION);
      } else {
        const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
        console.log('Generic handling for ', tokenName);
        tokenPrice = await this.getLPTokenPrice(token, null);
        tokenPrice = (Number(tokenPrice) * Number(priceOfOneFtmInDollars)).toString();
      }
    }
    return tokenPrice;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //=========================== END ===================================
  //===================================================================

  async getCurrentEpoch(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.epoch();
  }

  async getBondOraclePriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBondPremiumRate();
  }

  /**
   * Buy bonds with cash.
   * @param amount amount of cash to purchase bonds with.
   */
  async buyBonds(amount: string | number): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const treasuryTombPrice = await Treasury.getLionPrice();
    return await Treasury.buyBonds(decimalToBalance(amount), treasuryTombPrice);
  }

  /**
   * Buy bonds with cash.
   * @param amount amount of cash to purchase bonds with.
   */
  async buyBearBonds(amount: string | number): Promise<TransactionResponse> {
    const { BearTreasury } = this.contracts;
    const bearTreasuryTombPrice = await BearTreasury.getBearPrice();
    return await BearTreasury.buyBonds(decimalToBalance(amount), bearTreasuryTombPrice);
  }

  /**
   * Redeem bonds for cash.
   * @param amount amount of bonds to redeem.
   */
  async redeemBonds(amount: string): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const priceForTomb = await Treasury.getLionPrice();
    console.log(decimalToBalance(amount).toString(), priceForTomb.toString());
    return await Treasury.redeemBonds(decimalToBalance(amount), priceForTomb);
  }

  /**
   * Redeem bonds for cash.
   * @param amount amount of bonds to redeem.
   */
  async redeemBearBonds(amount: string): Promise<TransactionResponse> {
    const { BearTreasury } = this.contracts;
    const priceForBear = await BearTreasury.getBearPrice();
    console.log(decimalToBalance(amount).toString(), priceForBear.toString());
    return await BearTreasury.redeemBonds(decimalToBalance(amount), priceForBear);
  }

  async getTotalValueLocked(): Promise<number> {
    let totalValue = 0;

    for (const bankInfo of Object.values(bankDefinitions)) {
      if (bankInfo != null && !bankInfo.closedForStaking && !bankInfo.contract?.includes('Vault')) {
        totalValue += Number(getDisplayBalance((await this.getPoolAPRs(bankInfo)).TVL, 6, 2));
      }
    }

    const TSHAREPrice = (await this.getShareStat()).priceInDollars;
    const masonrytShareBalanceOf = await this.TSHARE.balanceOf(this.currentMasonry().address);
    const masonryTVL = Number(getDisplayBalance(masonrytShareBalanceOf, this.TSHARE.decimal)) * Number(TSHAREPrice);

    return totalValue + masonryTVL;
  }

  /**
   * Calculates the price of an LP token
   * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
   * @param lpToken the token under calculation
   * @param token the token pair used as reference (the other one would be FTM in most cases)
   * @returns price of the LP token
   */
  async getLPTokenPrice(lpToken: ERC20, token: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    if (token == null) {
      const pair = getPairFromAddress(lpToken.address, this.provider);
      token = await this.getERC20InstanceFromContract(await pair.token1());
      console.log('Automatically picked up token in LP for price calcualation: ', token.symbol);
    }
    const totalSupply = getDisplayBalance(await lpToken.totalSupply(), 18, 18);
    //Get amount of tokenA
    const tokenSupply = getDisplayBalance(await token.balanceOf(lpToken.address), token.decimal, token.decimal);
    const priceOfToken = await this.getTokenPriceFromPancakeswap(token);
    const tokenInLP = Number(tokenSupply) / Number(totalSupply);
    const tokenPrice = (Number(priceOfToken) * tokenInLP * 2) //We multiply by 2 since half the price of the lp token is the price of each piece of the pair. So twice gives the total
      .toString();
    console.log(
      'Price of ',
      token.symbol,
      ' is ',
      priceOfToken,
      'Token suply for LP SUPPLY',
      tokenInLP,
      'lp price is ',
      tokenPrice,
    );
    return tokenPrice;
  }

  async earnedFromBank(
    poolName: ContractName,
    earnTokenName: String,
    poolId: Number,
    account = this.myAccount,
  ): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      if (earnTokenName === 'LION') {
        return await pool.pendingLION(poolId, account);
      } else if (earnTokenName === 'TIGER') {
        return await pool.pendingTIGER(poolId, account);
      } else {
        return BigNumber.from(0);
      }
    } catch (err) {
      console.error(`Failed to call earned() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }

  async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      const userInfo = await pool.userInfo(poolId, account);
      if (poolName.includes('Vault')) {
        const strategy = this.contracts[poolName + 'Strategy'];
        const pricePerShare = await strategy.getPricePerFullShare();
        return await userInfo.shares.mul(pricePerShare.div(1e10)).div(1e8);
      } else {
        return await userInfo.amount;
      }
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }

  async getPendingRewardOnVault(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      console.log(poolName);
      if (poolName.includes('Vault')) {
        return await pool.pendingReward(poolId, 0, this.myAccount);
      } else {
        return BigNumber.from(0);
      }
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }

  /**
   * Migrates a token to the new address.
   *
   * @param oldContract old contract to migrate from
   * @param amount amount of tokens to migrate
   * @param newContract new contract to migrate to
   */
  async migrate(oldContract: ContractName, amount: BigNumber, newContract: ContractName): Promise<TransactionResponse> {
    const { Migrator } = this.contracts;
    return await Migrator.migrate(oldContract, amount, newContract);
  }

  /**
   * Deposits token to given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async stake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.deposit(poolId, amount);
  }

  /**
   * Withdraws token from given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async unstake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.withdraw(poolId, amount);
  }

  /**
   * Transfers earned token reward from given pool to my account.
   */
  async harvest(poolName: ContractName, poolId: Number): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
    return await pool.withdraw(poolId, 0);
  }

  /**
   * Harvests and withdraws deposited tokens from the pool.
   */
  async exit(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    let userInfo = await pool.userInfo(poolId, account);
    return await pool.withdraw(poolId, userInfo.amount);
  }

  /**
   * Harvests and withdraws deposited tokens from the pool.
   */
  async withdrawAll(vaultName: ContractName, poolId: Number, account = this.myAccount): Promise<TransactionResponse> {
    const vault = this.contracts[vaultName];
    return await vault.withdrawAll(poolId);
  }

  async fetchMasonryVersionOfUser(): Promise<string> {
    return 'latest';
  }

  currentMasonry(): Contract {
    return this.contracts.Masonry;
  }

  isOldMasonryMember(): boolean {
    return this.masonryVersionOfUser !== 'latest';
  }

  fetchAllTokenPrices = async () => {
    //console.log("Fetching all token prices");
    const allInfosPrices = {};
    let info: number;
    const btcAddress = '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599';
    const allAddresses = [
      btcAddress,
      ...WHITELISTED_TOKENS.filter(
        (t) =>
          !t.lp &&
          t.name !== 'multiUSDC' &&
          t.name !== 'multiUSDT' &&
          t.name !== 'multiDAI' &&
          t.name !== 'USDt' &&
          t.name !== 'axlUSDC',
      ).map((t) => t.address),
    ];
    WHITELISTED_TOKENS.filter((t) => !t.lp).forEach(async (t) => {
      const allInfos = (await (await fetch(`https://api.dexscreener.com/latest/dex/tokens/${t.address}`)).json()).pairs;
      const tokenName = t.name;
      if (
        tokenName === 'multiUSDC' ||
        tokenName === 'multiUSDT' ||
        tokenName === 'multiDAI' ||
        tokenName === 'USDt' ||
        tokenName === 'axlUSDC'
      ) {
        info = 1;
      } else if (tokenName === 'WBTC') {
        const allInfosBtc = (await (await fetch(`https://api.dexscreener.com/latest/dex/tokens/${btcAddress}`)).json())
          .pairs;
        info = allInfosBtc
          .filter((p) => p.chainId === 'ethereum' && p.dexId === 'uniswap' && p.baseToken.address === btcAddress)
          .sort((a, b) => b.liquidity.usd - a.liquidity.usd)?.[0]?.priceUsd;
      } else if (tokenName === 'WKAVA' || tokenName === 'WKAVAv1') {
        const infos = allInfos
          .filter(
            (p) =>
              p.chainId === 'kava' &&
              p.dexId === 'equilibre' &&
              p.baseToken.address === WHITELISTED_TOKENS.find((t) => t.name === 'USDt').address &&
              p.quoteToken.address === t.address,
          )
          ?.sort((a, b) => {
            return Number(b.liquidity?.usd) - Number(a.liquidity?.usd);
          });
        info = 1 / infos?.[0]?.priceNative;
      } else if (tokenName === 'LION') {
        const infos = allInfos
          .filter(
            (p) =>
              p.chainId === 'kava' &&
              p.dexId === 'equilibre' &&
              p.baseToken.address === t.address &&
              p.quoteToken.address === WHITELISTED_TOKENS.find((t) => t.name === 'USDt').address,
          )
          ?.sort((a, b) => {
            return Number(b.liquidity?.usd) - Number(a.liquidity?.usd);
          });
        info = infos?.[0]?.priceNative;
      } else if (tokenName === 'TIGER') {
        const lionAddress = WHITELISTED_TOKENS.find((t) => t.name === 'LION')?.address;
        const usdtAddress = WHITELISTED_TOKENS.find((t) => t.name === 'USDt')?.address;
        const infosLion = (
          await (await fetch(`https://api.dexscreener.com/latest/dex/tokens/${lionAddress}`)).json()
        ).pairs
          .filter(
            (p) =>
              p.chainId === 'kava' &&
              p.dexId === 'equilibre' &&
              p.quoteToken.address === usdtAddress &&
              p.baseToken.address === lionAddress,
          )
          ?.sort((a, b) => {
            return Number(a.liquidity?.usd) - Number(b.liquidity?.usd);
          });
        const infos = allInfos
          .filter(
            (p) =>
              p.chainId === 'kava' &&
              p.dexId === 'equilibre' &&
              p.quoteToken.address === WHITELISTED_TOKENS.find((t) => t.name === 'LION').address &&
              p.baseToken.address === t.address,
          )
          ?.sort((a, b) => {
            return Number(b.liquidity?.usd) - Number(a.liquidity?.usd);
          });

        const infoLion = infosLion?.[0]?.priceNative;
        info = infos?.[0]?.priceNative * infoLion;
        console.log('Tiger price', info, 'Lion Price', infoLion);
      } else {
        const infos = allInfos
          ?.filter((p) => p.chainId === 'kava' && p.dexId === 'equilibre' && p.baseToken.address === t.address)
          ?.sort((a, b) => {
            return Number(a.liquidity?.usd) - Number(b.liquidity?.usd);
          });
        info = infos?.[0]?.priceUsd;
      }
      allInfosPrices[t.address] = Number(info);
    });
    //console.log("Fetched all token prices", allInfosPrices);
    return allInfosPrices;
  };

  getTokenPrice = async (address: string) => {
    const tokenName = WHITELISTED_TOKENS.find((t) => t.address === address)?.name;
    if (tokenName === 'multiUSDC') return 1;
    if (tokenName === 'multiUSDT') return 1;
    if (tokenName === 'multiDAI') return 1;
    if (tokenName === 'USDt') return 1;
    const info: number = this.allTokenPrices?.[address];
    console.log('Fetched token price', tokenName, address, info);
    return info;
  };

  async getTokenPriceFromPancakeswap(token: ERC20): Promise<string> {
    if (!this.allTokenPrices) {
      this.allTokenPrices = await this.fetchAllTokenPrices();
    }
    return this.getTokenPrice(token.address);
  }

  async getTokenPriceFromSpiritswap(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;

    const { WFTM } = this.externalTokens;

    const wftm = new TokenSpirit(chainId, WFTM.address, WFTM.decimal);
    const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const wftmToToken = await FetcherSpirit.fetchPairData(wftm, token, this.provider);
      const liquidityToken = wftmToToken.liquidityToken;
      let ftmBalanceInLP = await WFTM.balanceOf(liquidityToken.address);
      let ftmAmount = Number(getFullDisplayBalance(ftmBalanceInLP, WFTM.decimal));
      let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
      let shibaAmount = Number(getFullDisplayBalance(shibaBalanceInLP, tokenContract.decimal));
      const priceOfOneFtmInDollars = await this.getWFTMPriceFromPancakeswap();
      let priceOfShiba = (ftmAmount / shibaAmount) * Number(priceOfOneFtmInDollars);
      return priceOfShiba.toString();
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  /*
  async getWFTMPriceFromPancakeswap(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WFTM, USDC } = this.externalTokens;
    try {
      const fusdt_wftm_lp_pair = this.externalTokens['USDC-SVN-LP'];
      let ftm_amount_BN = await WFTM.balanceOf(fusdt_wftm_lp_pair.address);
      let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
      let fusdt_amount_BN = await USDC.balanceOf(fusdt_wftm_lp_pair.address);
      let fusdt_amount = Number(getFullDisplayBalance(fusdt_amount_BN, USDC.decimal));
      return (fusdt_amount / ftm_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of wCRO: ${err.message}`);
    }
  }
  */

  async getWFTMPriceFromPancakeswap(): Promise<string> {
    return '1.00';
  }

  async getBaseTokenForToken(token: ERC20): Promise<string> {
    let baseToken;
    if (token.symbol === 'BEAR') {
      baseToken = this.externalTokens['WBTC'];
    } else if (token.symbol === 'LION') {
      baseToken = this.externalTokens['USDT'];
    } else if (token.symbol === 'TIGER') {
      baseToken = this.externalTokens['LION'];
    } else if (token.symbol === 'MSHARE') {
      baseToken = this.externalTokens['MMF'];
    } else if (token.symbol === 'MMF') {
      baseToken = this.externalTokens['MMF'];
    }
    //console.log(`Base token for ${token.symbol} is `,baseToken)
    return baseToken;
  }

  async getTokenPriceInUSDCFromPancakeswap(token: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WFTM, USDC } = this.externalTokens;
    try {
      if (token.symbol === 'WBTC') {
        return this.getTokenPriceFromPancakeswap(token);
      } else {
        const fusdt_wftm_lp_pair = this.externalTokens[`USDC-${token.symbol}-LP`];
        //console.log(`Using pool USDC-${token.symbol}-LP:`,fusdt_wftm_lp_pair)
        let ftm_amount_BN = await token.balanceOf(fusdt_wftm_lp_pair.address);
        let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
        let fusdt_amount_BN = await USDC.balanceOf(fusdt_wftm_lp_pair.address);
        let fusdt_amount = Number(getFullDisplayBalance(fusdt_amount_BN, USDC.decimal));
        console.log(`${token.symbol} price is ${(fusdt_amount / ftm_amount).toString()}`);
        return (fusdt_amount / ftm_amount).toString();
      }
    } catch (err) {
      console.error(`Failed to fetch token price of wCRO: ${err.message}`);
    }
  }

  //===================================================================
  //===================================================================
  //===================== MASONRY METHODS =============================
  //===================================================================
  //===================================================================

  async getMasonryAPR() {
    const Masonry = this.currentMasonry();
    const latestSnapshotIndex = await Masonry.latestSnapshotIndex();
    const lastHistory = await Masonry.masonryHistory(latestSnapshotIndex);

    const lastRewardsReceived = lastHistory[1];

    const TSHAREPrice = (await this.getShareStat()).priceInDollars;
    const TOMBPrice = (await this.getTombStat()).priceInDollars;
    const epochRewardsPerShare = lastRewardsReceived / 1e18;

    //Mgod formula
    const amountOfRewardsPerDay = epochRewardsPerShare * Number(TOMBPrice) * 3;
    const masonrytShareBalanceOf = await this.TSHARE.balanceOf(Masonry.address);
    const masonryTVL = Number(getDisplayBalance(masonrytShareBalanceOf, this.TSHARE.decimal)) * Number(TSHAREPrice);
    const realAPR = ((amountOfRewardsPerDay * 100) / masonryTVL) * 365;
    return realAPR;
  }

  async getBearMasonryAPR() {
    const Masonry = this.contracts.BearMasonry;
    const latestSnapshotIndex = await Masonry.latestSnapshotIndex();
    const lastHistory = await Masonry.masonryHistory(latestSnapshotIndex);

    const lastRewardsReceived = lastHistory[1];

    const TSHAREPrice = await this.getTokenPriceFromPancakeswap(this.TSHARE);
    const peg = await this.getTokenPriceFromPancakeswap(this.externalTokens.BEAR);
    const BEARPrice = peg * (await this.getTokenPriceFromPancakeswap(this.externalTokens.WBTC));
    const epochRewardsPerShare = lastRewardsReceived / 1e18;
    console.log(`Price of Tiger ${TSHAREPrice} BEAR ${BEARPrice}`);
    //Mgod formula
    const amountOfRewardsPerDay = epochRewardsPerShare * Number(BEARPrice) * 3;
    const masonrytShareBalanceOf = await this.TSHARE.balanceOf(this.contracts.Masonry.address);
    const masonryTVL = Number(getDisplayBalance(masonrytShareBalanceOf, this.TSHARE.decimal)) * Number(TSHAREPrice);
    const realAPR = ((amountOfRewardsPerDay * 100) / masonryTVL) * 365;
    return realAPR;
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserClaimRewardFromMasonry(): Promise<boolean> {
    const Masonry = this.contracts.BearMasonry;
    return await Masonry.canClaimReward(this.myAccount);
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserUnstakeFromMasonry(): Promise<boolean> {
    const Masonry = this.contracts.BearMasonry;
    const canWithdraw = await Masonry.canWithdraw(this.myAccount);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    const notStaked = Number(getDisplayBalance(stakedAmount, this.TSHARE.decimal, 8)) === 0;
    const result = notStaked ? true : canWithdraw;
    return result;
  }

  async canUserUnstakeFromNewScrub(): Promise<boolean> {
    const Masonry = this.contracts.BearMasonry;
    const canWithdraw = await Masonry.canWithdraw(this.myAccount);
    const stakedAmount = await Masonry.balanceOf(this.myAccount);
    const notStaked = Number(getDisplayBalance(stakedAmount, this.TSHARE.decimal, 8)) === 0;
    const result = notStaked ? true : canWithdraw;
    return result;
  }

  async timeUntilClaimRewardFromMasonry(): Promise<BigNumber> {
    // const Masonry = this.currentMasonry();
    // const mason = await Masonry.masons(this.myAccount);
    return BigNumber.from(0);
  }

  async getTotalStakedInMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    return await Masonry.totalSupply();
  }

  async stakeShareToMasonry(amount: string): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.stake(decimalToBalance(amount));
  }

  async getStakedSharesOnMasonry(): Promise<BigNumber> {
    const Masonry = this.contracts.BearMasonry;
    return await Masonry.balanceOf(this.myAccount);
  }

  async getEarningsOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    return await Masonry.earned(this.myAccount);
  }

  async withdrawShareFromMasonry(amount: string): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.withdraw(decimalToBalance(amount));
  }

  async harvestCashFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.claimReward();
  }

  async exitFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();

    return await Masonry.exit();
  }

  async getTreasuryNextAllocationTime(): Promise<AllocationTime> {
    const { Treasury } = this.contracts;
    const nextEpochTimestamp: BigNumber = await Treasury.nextEpochPoint();
    const nextAllocation = new Date(nextEpochTimestamp.mul(1000).toNumber());
    const prevAllocation = new Date(Date.now());

    return { from: prevAllocation, to: nextAllocation };
  }
  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to claim
   * their reward from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserClaimRewardTime(): Promise<AllocationTime> {
    const { Masonry, BearMasonry, BearTreasury } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint(); //in unix timestamp
    const currentEpoch = await BearMasonry.epoch();
    const mason = await BearMasonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await BearTreasury.PERIOD();
    const periodInHours = period / 60 / 60; // 8 hours, period is displayed in seconds which is 21600
    const rewardLockupEpochs = await BearMasonry.rewardLockupEpochs();
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(rewardLockupEpochs);

    const fromDate = new Date(Date.now());
    if (targetEpochForClaimUnlock - currentEpoch <= 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
      const endDate = moment(toDate)
        .add(delta * periodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to unstake
   * from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserUnstakeTime(): Promise<AllocationTime> {
    const { BearMasonry, Treasury, Masonry } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint();
    const currentEpoch = await BearMasonry.epoch();
    const mason = await BearMasonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await Treasury.PERIOD();
    const PeriodInHours = period / 60 / 60;
    const withdrawLockupEpochs = await BearMasonry.withdrawLockupEpochs();
    const fromDate = new Date(Date.now());
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(withdrawLockupEpochs);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    if (currentEpoch <= targetEpochForClaimUnlock && Number(stakedAmount) === 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
      const endDate = moment(toDate)
        .add(delta * PeriodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  async provideTombFtmLP(ftmAmount: string, tombAmount: BigNumber): Promise<TransactionResponse> {
    const { TaxOffice } = this.contracts;
    let overrides = {
      value: parseUnits(ftmAmount, 18),
    };
    return await TaxOffice.addLiquidityETHTaxFree(
      tombAmount,
      tombAmount.mul(992).div(1000),
      parseUnits(ftmAmount, 18).mul(992).div(1000),
      overrides,
    );
  }

  /**
   * @returns an array of the regulation events till the most up to date epoch
   */
  async listenForRegulationsEvents(): Promise<any> {
    const { Treasury } = this.contracts;

    const treasuryDaoFundedFilter = Treasury.filters.DaoFundFunded();
    const treasuryDevFundedFilter = Treasury.filters.DevFundFunded();
    const treasuryMasonryFundedFilter = Treasury.filters.MasonryFunded();
    const boughtBondsFilter = Treasury.filters.BoughtBonds();
    const redeemBondsFilter = Treasury.filters.RedeemedBonds();

    let epochBlocksRanges: any[] = [];
    let masonryFundEvents = await Treasury.queryFilter(treasuryMasonryFundedFilter);
    var events: any[] = [];
    masonryFundEvents.forEach(function callback(value, index) {
      events.push({ epoch: index + 1 });
      events[index].masonryFund = getDisplayBalance(value.args[1]);
      if (index === 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
      }
      if (index > 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
        epochBlocksRanges[index - 1].endBlock = value.blockNumber;
      }
    });

    epochBlocksRanges.forEach(async (value, index) => {
      events[index].bondsBought = await this.getBondsWithFilterForPeriod(
        boughtBondsFilter,
        value.startBlock,
        value.endBlock,
      );
      events[index].bondsRedeemed = await this.getBondsWithFilterForPeriod(
        redeemBondsFilter,
        value.startBlock,
        value.endBlock,
      );
    });
    let DEVFundEvents = await Treasury.queryFilter(treasuryDevFundedFilter);
    DEVFundEvents.forEach(function callback(value, index) {
      events[index].devFund = getDisplayBalance(value.args[1]);
    });
    let DAOFundEvents = await Treasury.queryFilter(treasuryDaoFundedFilter);
    DAOFundEvents.forEach(function callback(value, index) {
      events[index].daoFund = getDisplayBalance(value.args[1]);
    });
    return events;
  }

  /**
   * Helper method
   * @param filter applied on the query to the treasury events
   * @param from block number
   * @param to block number
   * @returns the amount of bonds events emitted based on the filter provided during a specific period
   */
  async getBondsWithFilterForPeriod(filter: EventFilter, from: number, to: number): Promise<number> {
    const { Treasury } = this.contracts;
    const bondsAmount = await Treasury.queryFilter(filter, from, to);
    return bondsAmount.length;
  }

  async estimateZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]> {
    const { zapper } = this.contracts;
    const lpToken = getPairFromAddress(this.externalTokens[lpName].address, this.provider);
    let estimate;
    const token0 = await this.getERC20InstanceFromContract(await lpToken.token0());
    const token1 = await this.getERC20InstanceFromContract(await lpToken.token1());
    if (tokenName === FTM_TICKER) {
      estimate = await zapper.estimateZapIn(lpToken.address, SPOOKY_ROUTER_ADDR, parseUnits(amount, 18));
    } else {
      let token = null;
      console.log(tokenName);
      if (tokenName === TOMB_TICKER || tokenName === LION_TICKER) {
        token = this.TOMB;
      } else if (tokenName === TSHARE_TICKER || tokenName === TIGER_TICKER) {
        token = this.TSHARE;
      } else if (tokenName === BEAR_TICKER) {
        token = this.externalTokens.BEAR;
      } else if (tokenName === MSHARE_TICKER) {
        token = this.externalTokens.MSHARE;
      } else if (tokenName === WBTC_TICKER) {
        token = this.externalTokens.WBTC;
      } else if (tokenName === MMF_TICKER) {
        token = this.externalTokens.MMF;
      } else if (tokenName === DARKCRYSTL_TICKER) {
        token = this.externalTokens.DARKCRYSTL;
      } else if (tokenName === MINE_TICKER) {
        token = this.externalTokens.MINE;
      } else if (tokenName === MUSD_TICKER) {
        token = this.externalTokens.MUSD;
      } else if (tokenName === WCRO_TICKER) {
        token = this.externalTokens.WCRO;
      } else {
        token = this.externalTokens.USDC;
      }

      const precision = Math.min(8, token.decimal);
      const convertedAmount = BigNumber.from((amount * 10 ** precision).toFixed(0)).mul(
        10 ** (token.decimal - precision),
      );
      estimate = await zapper.estimateZapInToken(token.address, lpToken.address, ELK_ROUTER_ADDR, convertedAmount);
    }
    return [
      getDisplayBalance(estimate[0], token0.decimal, Math.min(8, token0.decimal)),
      getDisplayBalance(estimate[1], token1.decimal, Math.min(8, token1.decimal)),
      getDisplayBalance(estimate[2], 18, 8),
    ];
  }

  private isMMFToken(token: ERC20) {
    return (
      token == this.externalTokens.MMF ||
      token == this.externalTokens.SVN ||
      token == this.externalTokens.DARKCRYSTL ||
      token == this.externalTokens.MINE ||
      token == this.externalTokens.MUSD ||
      token == this.externalTokens.WCRO
    );
  }

  async zapIn(tokenName: string, lpName: string, amount: string): Promise<TransactionResponse> {
    // TODO: add annex router address for new zaps
    return;
    const { zapper } = this.contracts;
    const lpToken = this.externalTokens[lpName];

    let token: ERC20;
    if (tokenName === TOMB_TICKER || tokenName === LION_TICKER) {
      token = this.TOMB;
    } else if (tokenName === TSHARE_TICKER || tokenName === TIGER_TICKER) {
      token = this.TSHARE;
    } else if (tokenName === BEAR_TICKER) {
      token = this.externalTokens.BEAR;
    } else if (tokenName === MSHARE_TICKER) {
      token = this.externalTokens.MSHARE;
    } else if (tokenName === WBTC_TICKER) {
      token = this.externalTokens.WBTC;
    } else if (tokenName === MMF_TICKER) {
      token = this.externalTokens.MMF;
    } else if (tokenName === DARKCRYSTL_TICKER) {
      token = this.externalTokens.DARKCRYSTL;
    } else if (tokenName === MINE_TICKER) {
      token = this.externalTokens.MINE;
    } else if (tokenName === MUSD_TICKER) {
      token = this.externalTokens.MUSD;
    } else if (tokenName === WCRO_TICKER) {
      token = this.externalTokens.WCRO;
    } else {
      token = this.externalTokens.USDC;
    }
    const precision = Math.min(8, token.decimal);
    const convertedAmount = BigNumber.from((amount * 10 ** precision).toFixed(0)).mul(
      10 ** (token.decimal - precision),
    );
    return await zapper.zapInToken(token.address, convertedAmount, lpToken.address, ELK_ROUTER_ADDR, this.myAccount);
  }

  async swapTBondToTShare(tbondAmount: BigNumber): Promise<TransactionResponse> {
    const { TShareSwapper } = this.contracts;
    return await TShareSwapper.swapTBondToTShare(tbondAmount);
  }

  async estimateAmountOfTShare(tbondAmount: string): Promise<string> {
    const { TShareSwapper } = this.contracts;
    try {
      const estimateBN = await TShareSwapper.estimateAmountOfTShare(parseUnits(tbondAmount, 18));
      return getDisplayBalance(estimateBN, 18, 6);
    } catch (err) {
      console.error(`Failed to fetch estimate tiger amount: ${err}`);
    }
  }

  async getTShareSwapperStat(address: string): Promise<TShareSwapperStat> {
    const { TShareSwapper } = this.contracts;
    const tshareBalanceBN = await TShareSwapper.getTShareBalance();
    const tbondBalanceBN = await TShareSwapper.getTBondBalance(address);
    const rateTSharePerTombBN = await TShareSwapper.getTShareAmountPerTomb();
    const tshareBalance = getDisplayBalance(tshareBalanceBN, 18, 5);
    const tbondBalance = getDisplayBalance(tbondBalanceBN, 18, 5);
    return {
      tshareBalance: tshareBalance.toString(),
      tbondBalance: tbondBalance.toString(),
      rateTSharePerTomb: rateTSharePerTombBN.toString(),
    };
  }

  async buyProduct(sku: uint): Promise<uint> {
    const { commerce } = this.contracts;
    return await commerce.buy(sku);
  }

  async buyProductUSDC(sku: uint): Promise<uint> {
    const { commerce } = this.contracts;

    return await commerce.buyUSDC(sku);
  }

  async getCommerceOpenInfo(): Promise<{ openTrade: boolean; openTradeLion: boolean }> {
    const { commerce } = this.contracts;
    return {
      openTrade: (await commerce.openTradeFlag()) as boolean,
      openTradeLion: (await commerce.openTradeFlagLion()) as boolean,
    };
  }

  async getProductInfo(sku: uint): Promise<Product> {
    const { commerce } = this.contracts;
    return await commerce.products(sku);
  }

  async getProductInfo(sku: uint): Promise<Product> {
    const { commerce } = this.contracts;
    return await commerce.products(sku);
  }

  async getProducts(products: Product[], setNewProduct): Product[] {
    const { commerce } = this.contracts;
    if (!(await commerce.openTradeFlag())) {
      return [];
    }
    const totalProducts = await commerce.totalProducts();
    let multicall;
    if (this.config.chainId == 25) {
      multicall = new Multicall({
        ethersProvider: this.provider,
        tryAggregate: false,
      });
    } else {
      multicall = new Multicall({
        ethersProvider: this.provider,
        tryAggregate: false,
        multicallCustomContractAddress: this.contracts['Multicall'].address,
      });
    }
    const callListProducts = [];
    const callListProductPrices = [];
    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'commerce',
        contractAddress: this.deployments['commerce'].address,
        abi: this.deployments['commerce'].abi,
        calls: callListProducts,
      },
      {
        reference: 'commercePrices',
        contractAddress: this.deployments['commerce'].address,
        abi: this.deployments['commerce'].abi,
        calls: callListProductPrices,
      },
    ];
    for (let i = 0; i < totalProducts; i++) {
      try {
        callListProducts.push({ reference: i, methodName: 'products', methodParameters: [i] });
        callListProductPrices.push({ reference: i, methodName: 'getProductPrice', methodParameters: [i] });
      } catch {
        console.log('Failed to retrieve products!');
      }
    }
    multicall.call(contractCallContext).then((results) => {
      results.results.commerce.callsReturnContext.forEach((result, index) => {
        if (result.success) {
          const product: Product = {
            sku: Number(BigNumber.from(result.returnValues[0])),
            dollarValue: BigNumber.from(result.returnValues[1]),
            description: result.returnValues[2],
            descriptionExtended: result.returnValues[3],
            stock: Number(BigNumber.from(result.returnValues[4] ?? 1)),
            discount: Number(BigNumber.from(result.returnValues[5] ?? 0)),
            region: result.returnValues[6] ?? 0,
          };
          products?.push(product);
        }
      });
      results.results.commercePrices.callsReturnContext.forEach((result, index) => {
        if (result.success) {
          const product: Product = products[index];
          product.lionValue = BigNumber.from(result.returnValues[0]);
        }
      });
      setNewProduct(true);
    });
    return products;
  }

  async getOrders(setNewOrder): Promise<Order[]> {
    const { commerce } = this.contracts;
    const orderBookLength = await commerce.orderBookLength(this.myAccount);
    const orderBookList: Number[] = [];
    const orderBook: Order[] = [];
    let multicall;
    if (this.config.chainId == 25) {
      multicall = new Multicall({
        ethersProvider: this.provider,
        tryAggregate: false,
      });
    } else {
      multicall = new Multicall({
        ethersProvider: this.provider,
        tryAggregate: false,
        multicallCustomContractAddress: this.deployments['Multicall'].address,
      });
    }
    {
      //call to get order id list
      const callListOrderIds = [];
      let contractCallContext: ContractCallContext[] = [
        {
          reference: 'commerce',
          contractAddress: this.deployments['commerce'].address,
          abi: this.deployments['commerce'].abi,
          calls: callListOrderIds,
        },
      ];
      for (let i = 0; i < orderBookLength; i++) {
        try {
          callListOrderIds.push({ reference: i, methodName: 'orderBook', methodParameters: [this.myAccount, i] });
        } catch {
          console.log('Failed to retrieve orderIds!');
        }
      }
      multicall.call(contractCallContext).then((results) => {
        results.results.commerce?.callsReturnContext.forEach((result) => {
          if (result.success) {
            orderBookList?.push(Number(BigNumber.from(result.returnValues[0])));
          }
        });

        //call for order details

        const callListOrders = [];
        contractCallContext = [
          {
            reference: 'commerce',
            contractAddress: this.deployments['commerce'].address,
            abi: this.deployments['commerce'].abi,
            calls: callListOrders,
          },
        ];
        orderBookList.forEach((orderId) => {
          try {
            callListOrders.push({ reference: orderId, methodName: 'ordersPaid', methodParameters: [orderId] });
          } catch {
            console.log('Failed to retrieve orders!');
          }
        });
        multicall.call(contractCallContext).then((results) => {
          console.log('Started getting order details');
          results.results.commerce?.callsReturnContext.forEach((result) => {
            if (result.success) {
              const order: Order = {
                orderId: Number(BigNumber.from(result.returnValues[0])),
                user: result.returnValues[1],
                sku: Number(BigNumber.from(result.returnValues[2])),
                amountPaid: BigNumber.from(result.returnValues[3]),
                amountPaidUsdc: BigNumber.from(result.returnValues[4] ?? BigNumber.from(0)),
                paidInUsdc: result.returnValues[5],
                completed: result.returnValues[6],
                secret: result.returnValues[7],
              };
              orderBook.push(order);
              orderBook.sort((a, b) => {
                return Number(a.orderId) > Number(b.orderId) ? -1 : 1;
              });
              setNewOrder(true);
            }
          });
          console.log('finished with orders');
        });
      });
    }
    return orderBook;
  }

  toSignMessage(message: string): string {
    const { commerce } = this.contracts;
    const extendedMessage = commerce.address + '=>' + message;
    const hash = ethers.utils.keccak256('0x' + Buffer.from(extendedMessage).toString('hex'));
    return hash;
  }

  async getSignature(message: string): Promise<string> {
    const { commerce } = this.contracts;
    const signature = await this.signer.signMessage(ethers.utils.arrayify(this.toSignMessage(message)));
    console.log(this.signer.getAddress());
    console.log(signature);
    return signature;
  }
  async buyLionCave(amount: uint): Promise<uint> {
    const { lioncave } = this.contracts;
    return await lioncave.buy(amount);
  }

  async buyBearCave(amount: uint): Promise<uint> {
    const { bearcave } = this.contracts;
    return await bearcave.buy(amount);
  }

  async getLionCaveBalance(): Promise<uint> {
    const { lioncave } = this.contracts;
    return await this.TOMB.balanceOf(lioncave.address);
  }

  async resetLionBomb(contract): Promise<uint> {
    return await contract.reset();
  }

  async getLionBombInfo(contract): Promise<Info> {
    const lionbomb = contract;
    return {
      ticketPrice: BigNumber.from(0),
      currentBets: 0,
      epochFinish: 0,
      epochTimer: 0,
      currentWinner: '',
      burnFee: 0,
      nextBombFee: 0,
      minimumTimer: 0,
      jackpot: BigNumber.from(0),
    };
    const infos = await lionbomb.getInfo();
    return {
      ticketPrice: BigNumber.from(infos[0]),
      currentBets: Number(infos[1]),
      epochFinish: Number(infos[2]),
      epochTimer: Number(infos[3]),
      currentWinner: infos[4],
      burnFee: Number(infos[5]) / 100,
      nextBombFee: Number(infos[6]) / 100,
      minimumTimer: Number(infos[7]),
      jackpot: BigNumber.from(infos[8]),
    };
  }

  async migrateToKava(tokenName: string, amount: BigNumber) {
    await this.contracts['ScrubKavaMigrator'].deposit(this.externalTokens[tokenName].address, amount);
  }

  async migrateToKavaSinglePool() {
    await this.contracts['ScrubKavaMigrator'].migrateSinglePool();
  }

  async getMigrationKavaInfo(): Promise<MigrationUserInfo> {
    if (this.isUnlocked) {
      const info = await this.contracts['ScrubKavaMigrator'].userInfo(this.myAccount);
      const returnValue: MigrationUserInfo = {
        userAddress: info[0],
        usdcAmount: info[1],
        btcAmount: info[2],
        lionAmount: info[3],
        bearAmount: info[4],
        tigerAmount: info[5],
        lionLPAmount: info[6],
        bearLPAmount: info[7],
        tigerLPAmount: info[8],
        lionSinglePool: info[9],
        poolMigrated: info.poolMigrated,
      };
      console.log(returnValue);
      return returnValue;
    }
    return;
  }

  async getClaimerKavaInfo(): Promise<MigrationUserInfo> {
    if (this.isUnlocked) {
      const info = await this.contracts['Claimer'].userInfo(this.myAccount);
      const returnValue = {
        userAddress: info[0],
        usdcAmount: info[1],
        btcAmount: info[2],
        lionAmount: info[3],
        bearAmount: info[4],
        tigerAmount: info[5],
        lionLPAmount: 0,
        bearLPAmount: 0,
        tigerLPAmount: 0,
        lionSinglePool: info[6],
        migrated: info[7],
        poolMigrated: info[8],
      };
      return returnValue;
    }
    return;
  }

  async claimerClaim(): Promise<void> {
    if (this.isUnlocked) {
      const info = await this.contracts['Claimer'].claim();
    }
    return;
  }
}

import useChainId from 'hooks/useChainId';
import React, { createContext, useEffect, useState } from 'react';
import { useChain } from 'react-spring';
import { useWallet } from 'use-wallet';

import config from '../../config';
import TombFinance from '../../tomb-finance';

export interface TombFinanceContext {
  tombFinance?: TombFinance;
}

export const Context = createContext<TombFinanceContext>({ tombFinance: null });

export const TombFinanceProvider: React.FC = ({ children }) => {
  const { ethereum, account } = useWallet();
  const [tombFinance, setTombFinance] = useState<TombFinance>();
  const { chainId } = useChainId();
  console.log('Instantiating Scrub for chain ', chainId);
  useEffect(() => {
    async function connectWallet() {
      if (!tombFinance) {
        if (config[chainId]) {
          const tomb = new TombFinance(config[chainId]);
          if (account) {
            // wallet was unlocked at initialization
            tomb.unlockWallet(ethereum, account);
          }
          setTombFinance(tomb);
        }
        return;
      } else if (account && config[chainId]) {
        tombFinance.unlockWallet(ethereum, account);
        return;
      }
    }

    connectWallet();
  }, [account, ethereum, tombFinance, chainId]);

  return <Context.Provider value={{ tombFinance }}>{children}</Context.Provider>;
};

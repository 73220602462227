import React from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { BsLightningChargeFill } from 'react-icons/bs';

import Button from '@material-ui/core/Button';

import { CusBtn } from './btn.style';

export default function BtnContainer({
  width,
  height,
  text,
  clickFunc,
  disabled = false,
  family = 'ChakraBold',
  fontsize = '18px',
}) {
  return (
    <CusBtn width={width} height={height} family={family} fontsize={fontsize}>
      <Button variant="contained" color="primary" onClick={() => clickFunc()} disabled={disabled}>
        {text === 'plus' && <AiOutlinePlus />}
        {text === 'minus' && <AiOutlineMinus />}
        {text === 'lightning' && <BsLightningChargeFill />}
        {text !== 'plus' && text !== 'minus' && text !== 'lightning' && text}
      </Button>
    </CusBtn>
  );
}

import kavaLogo from 'assets/tokenImg/kava.svg';
import tigerLogo from 'assets/tokenImg/tiger.png';
import useTombFinance from 'hooks/useTombFinance';
import { useEffect, useState } from 'react';
import { BsDiscord } from 'react-icons/bs';
import { FaBars, FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import CustomBtn from 'uikit/BtnComponent';
import { useWallet } from 'use-wallet';

import { MenuItem, Select, makeStyles } from '@material-ui/core';
import useChainId from 'hooks/useChainId';
import usePromptNetwork from 'hooks/useNetworkPrompt';
import { white } from 'theme/colors';
import { maskAccount } from '../../utils/utils';
import ConnectModal from './ConnectModal';
import WalletModal from './WalletModal';
import { QuestionImg } from './connect.style';
import { Land, LandOut, LogoImg, ResTabs, SocialIcons, Tab, Tabs, WalletBtn } from './header.style';

export default function LandContainer() {
  const { ethereum } = window;
  const { account } = useWallet();
  const [resflag, setresflag] = useState(false);
  const [walletFlag, setWalletFlag] = useState(false);
  const [showConnect, setShowConnect] = useState(false);
  const { chainId, setChainId } = useChainId();

  const Changeresflag = () => {
    setresflag(!resflag);
  };
  const WalletFlag = () => {
    setWalletFlag(true);
  };

  const handleChangeNetwork = (event) => {
    console.log('requesting network change to', event.target.value);
    localStorage.setItem('scrubNetwork', event.target.value);
    setChainId(event.target.value);
  };

  const tombFinance = useTombFinance();
  const maskedAccount = account ? maskAccount(account) : 'MY WALLET';
  const location = useLocation();
  if (window.ethereum) {
    window.ethereum.on('networkChanged', function (event) {
      window.location.reload();
    });
  }
  useEffect(() => {
    if (location.pathname === '/nftcollection' || location.pathname === '/singlestake') {
      setChainId(2222);
    }
  }, []);

  usePromptNetwork(chainId);

  const useStyles = makeStyles((theme) => ({
    networkSelectionBox: {
      maxWidth: '150px',
      minWidth: '120px',
      alignContent: 'center',
      maxHeight: '50px',
      padding: '10px 10px 10px 10px',
      background: '#ff8718',
      borderRadius: '12px',
      border: 'solid',
      borderWidth: '0 0 4px',
      borderBottomColor: '#b35600',
      color: white,
      fontStyle: 'normal',
      fontFamily: 'ChakraBold',
      fontWeight: '400',
      lineHeight: '33px',
      fontsize: '18px',
      '@media screen and (max-width: 830px)': {
        marginTop: '-60px',
      },
    },
    networkSelection: {
      maxWidth: '150px',
      maxHeight: '40px',
      minWidth: '60px',
      alignContent: 'center',
      margin: '0px',
      padding: '5px',
      background: '#ff8718',
      borderRadius: '10px',
      color: white,
      fontStyle: 'normal',
      fontFamily: 'ChakraBold',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '33px',
      '&.MuiListItem-root.Mui-selected, &.MuiListItem-button:hover': {
        background: '#fec38b',
        borderRadius: '12px',
        color: white,
        fontStyle: 'normal',
        fontFamily: 'Chakra',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '33px',
      },
    },
    paper: {
      margin: '-10px',
      padding: '10px',
      background: '#ff8718',
      borderRadius: '12px',
      fontStyle: 'normal',
      fontFamily: 'ChakraBold',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '33px',
    },
    networkSelectionImg: {
      maxWidth: '30px',
    },
  }));
  const classes = useStyles();
  return (
    <Land>
      <LandOut>
        <Link to="/">
          <LogoImg src={tigerLogo} alt="" draggable={false} />
        </Link>
        <Tabs>
          <Link to="/">
            <Tab active={location.pathname === '/'}>HOME</Tab>
          </Link>
          <a href="https://invest.scrub.money">
            <Tab active={location.pathname === '/invest'}>INVEST</Tab>
          </a>

          <Link to="/nftcollection">
            <Tab active={location.pathname === '/nftcollection'}>NFT</Tab>
          </Link>
          <Link to="/singlestake">
            <Tab active={location.pathname === '/singlestake'}> TIGER STAKE</Tab>
          </Link>
          <a href="https://commerce.scrub.money">
            <Tab active={location.pathname === '/giftcards'}>GIFTCARDS</Tab>
          </a>
          {/*}

          <Link to="/lionbomb">
            <Tab active={location.pathname === '/lionbomb'}>LIONBOMB</Tab>
          </Link>
          <Link to="/dexisale">
            <Tab active={location.pathname === '/dexisale'}>LAUNCHPAD</Tab>
          </Link>
  */}
          <Tab>
            <a href="https://scrub.gitbook.io/scrub-doc/" target="_blank" rel="noopener noreferrer">
              DOCS
            </a>
          </Tab>
          <WalletBtn>
            <CustomBtn
              width="100%"
              text={maskedAccount}
              clickFunc={() => {
                if (!account) {
                  setShowConnect(true);
                } else {
                  WalletFlag();
                }
              }}
            />
          </WalletBtn>
        </Tabs>
        {!resflag ? (
          <FaBars className="ResbarIcon" onClick={() => Changeresflag()} />
        ) : (
          <MdClose className="ResbarIcon" onClick={() => Changeresflag(false)} />
        )}
        {resflag && (
          <ResTabs>
            <Link to="/">
              <Tab active={location.pathname === '/'} onClick={() => Changeresflag(false)}>
                HOME
              </Tab>
            </Link>
            <a href="https://invest.scrub.money">
              <Tab active={location.pathname.includes('invest')} onClick={() => Changeresflag(false)}>
                INVEST
              </Tab>
            </a>

            <Link to="/nftcollection">
              <Tab active={location.pathname === '/nftcollection'} onClick={() => Changeresflag(false)}>
                NFT
              </Tab>
            </Link>
            <Link to="/singlestake">
              <Tab active={location.pathname === '/singlestake'} onClick={() => Changeresflag(false)}>
                TIGER STAKE
              </Tab>
            </Link>
            <a href="https://commerce.scrub.money">
              <Tab active={location.pathname === '/giftcards'} onClick={() => Changeresflag(false)}>
                GIFTCARDS
              </Tab>
            </a>

            {/*<Link to="/dexisale">
              <Tab active={location.pathname === '/dexisale'} onClick={() => Changeresflag(false)}>
                LAUNCHPAD
              </Tab>
            </Link>
        */}
            <Tab>
              <a href="https://scrub.gitbook.io/scrub-doc/" target="_blank" rel="noopener noreferrer">
                DOCS
              </a>
            </Tab>
            <WalletBtn>
              <CustomBtn
                width="100%"
                height="50px"
                text={maskedAccount}
                clickFunc={() => {
                  if (!account) {
                    setShowConnect(true);
                  } else {
                    WalletFlag();
                  }
                }}
              />
            </WalletBtn>
            <SocialIcons>
              <a href="https://twitter.com/ScrubMoneyDeFi" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="socialIcon" />
              </a>
              <a href="https://t.me/ScrubMoney" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane className="socialIcon" />
              </a>
              <a href="https://discord.gg/dPVjyrUBey" target="_blank" rel="noopener noreferrer">
                <BsDiscord className="socialIcon" />
              </a>
            </SocialIcons>
          </ResTabs>
        )}
      </LandOut>
      {!resflag && (
        <Select
          MenuProps={{
            classes: {
              paper: classes.paper,
            },
          }}
          defaultValue={chainId}
          label="Network"
          onChange={handleChangeNetwork}
          className={classes.networkSelectionBox}
          disabled={
            location.pathname === '/dexisale' ||
            location.pathname === '/nftcollection' ||
            location.pathname === '/singlestake'
          }
        >
          <MenuItem className={classes.networkSelection} value={2222}>
            <QuestionImg className={classes.networkSelectionImg} src={kavaLogo} alt="" draggable="false" />
            Kava
          </MenuItem>
        </Select>
      )}
      {walletFlag && <WalletModal tombFinance={tombFinance} setWalletFlag={setWalletFlag} />}
      <ConnectModal showConnect={showConnect} setShowConnect={setShowConnect} />
    </Land>
  );
}
